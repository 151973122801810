import { LogService } from './../../../services/log.service';
import { Component, ViewChild, Input, OnChanges, ViewEncapsulation, isDevMode, ElementRef, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { RelatedTask, Task, TaskComment, TaskStatusEnum } from 'src/app/models/task.model';
import { TaskService } from 'src/app/services/task.service';
import { Project } from 'src/app/models/project.model';
import { ProjectService } from 'src/app/services/project.service';
import { User } from 'src/app/models/user.model';
import { AccountService } from 'src/app/services/account.service';
import { LogType } from 'src/app/models/log-type.model';
import { CommentsService } from 'src/app/services/comments.service';
import { AuthService } from 'src/app/services/auth.service';
import * as _ from 'lodash';
import { UtilitiesService } from 'src/app/services/utilities.service';

export class MyModule {}
@Component({
  selector: 'app-task-editor',
  templateUrl: './task-editor.component.html',
  styleUrls: ['./task-editor.component.scss'],
})

export class TaskEditorComponent implements OnChanges, AfterContentChecked {
  @ViewChild('form', { static: true })
  private form: NgForm;
  @ViewChild('tName') tName!: ElementRef;
  isNewTask = false;
  disableAnimation = true;
  public isSaving = false;
  public sameAsMailing = false;
  public taskId : number;
  private onTaskSaved = new Subject<Task>();
  public projects = Array<Project>();
  public users = Array<User>();
  public dependentTaskList = Array<Task>();
  public relatedTaskList = Array<RelatedTask>();
  TaskStatusEnum = TaskStatusEnum;

  @Input() edit: Boolean;
  @Input() task: Task = new Task();
  @Input() isEditMode = false;
  minDate = new Date();
  remainingTime:any;
  estimatedTime:any;
  taskProfileForm: FormGroup;
  taskSaved$ = this.onTaskSaved.asObservable();
  defaultTimeEstimate = 1;

  constructor(
    private taskService: TaskService,
    private alertService: AlertService,
    private projectService: ProjectService,
    private commentService: CommentsService,
    private accountService: AccountService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private cd : ChangeDetectorRef,
    private logger: LogService,
    public utilitiesService: UtilitiesService
  ) {
    this.buildForm();
  }

  ngOnChanges() {
    this.accountService.getUser().subscribe(
      user => {
        if(user.defaultTimeEstimate){
          //patch the default time estimate to the form
          this.taskProfileForm.patchValue({
            timeEstimate: user.defaultTimeEstimate
          });
        }
      },
      error => console.log(error)
    );


    if (this.task == null) {
      this.isNewTask = true;
      this.task = new Task();
      this.task.active = true;
    } else {
      this.isNewTask = false;
    }
    this.resetForm();
  }

  ngOnInit() {
    this.cd.detach();
    if(this.task?.dependentTaskId){
      this.taskService.getTask(this.task.dependentTaskId).subscribe(res => {
        this.dependentTaskList.push(res);
      });
    }
    this.minDate.setDate(this.minDate.getDate() - 1);
    this.remainingTime = this.task.timeLeft;
    this.estimatedTime = this.task.timeEstimate;

    this.projectService.getProjects().subscribe(
      x => {
        this.projects = x;
        this.projects = _.orderBy(this.projects, ['name'], ['asc']);
      },
      err => {
        console.log(err)
      }
  );
    this.accountService.getUsers().subscribe(res => this.users = res, error => this.users = (error as any));
  }

  ngAfterViewInit() {
    //focus the task name on load
    this.tName.nativeElement.focus();
  }

  ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  public setTask(task?: Task) {
    this.task = task;
    this.ngOnChanges();
  }

  private buildForm() {
    this.taskProfileForm = this.formBuilder.group({
      name: ['', Validators.required],
      details:  ['', Validators.required],
      active: true,
      projectId: ['', Validators.required],
      dependentTaskId: [''],
      taskStatus: [''],
      dueDate: ['', Validators.compose([
        Validators.required,
        this.utilitiesService.ValidationUtils.dateRangeValidator(this.minDate)
      ])],
      timeEstimate:['', Validators.required],
      timeLeft:[''],
      notes:[''],
      approver:[''],
      assignedToId:[''],
      billable:['']
    });
  }

  public resetForm(stopEditing = false) {
    if (stopEditing) {
      this.isEditMode = false;
    }

    if (!this.task) {
      this.isNewTask = true;
      this.task = new Task();
    }



    this.taskProfileForm.reset({
      name: this.task.name || '',
      details: this.task.details || '',
      active: this.task.active || '',
      projectId: this.task.projectId || '',
      taskStatus: this.task.taskStatus || '',
      dueDate: this.task.dueDate || '',
      timeEstimate: Number(this.defaultTimeEstimate),
      notes: this.task.notes || '',
      approver: this.task.approver || '',
      assignedToId: this.task.assignedToId || '',
      billable: true,
      approvalTaskId:this.task.approvalTaskId,
      dependentTaskId :this.task.dependentTaskId
    });
  }

  public beginEdit() {
    this.isEditMode = true;
  }

  public save() {
    if (!this.form.submitted) {
      this.form.onSubmit(null);
      return;
    }

    if (!this.taskProfileForm.valid) {
      this.alertService.showValidationError();
      return;
    }

    this.isSaving = true;
    const editedTask = this.getEditedTask();
    if (this.isNewTask) {
      this.taskService.newTask(editedTask).subscribe(x => {
        this.saveCompleted(x)
      })
    }
     else {
      this.taskService.updateTask(editedTask).subscribe(
        () => this.saveCompleted(editedTask),
        error => this.saveFailed(error));
    }
  }

  public cancel() {
    this.resetForm();
    this.isEditMode = false;
    this.alertService.resetStickyMessage();
  }

  private getEditedTask(): Task {
    const formModel = this.taskProfileForm.value;

    return {
      id: this.task.id,
      name: formModel.name,
      details: formModel.details,
      active: formModel.active,
      projectId:formModel.projectId,
      taskStatus: Number(formModel.taskStatus),
      dueDate: formModel.dueDate,
      timeEstimate: Number(formModel.timeEstimate),
      timeLeft: this.remainingTime ? this.remainingTime: this.task.timeLeft,
      notes: formModel.notes,
      approver: formModel.approver,
      assignedToId: this.edit ? this.authService.currentUser.id : formModel.assignedToId,
      billable:formModel.billable,
      dependentTaskId: formModel.dependentTaskId > 0 ? Number(formModel.dependentTaskId) : null,
      approvalTaskId : this.task.approvalTaskId,
      parentTaskId:formModel.parentTaskId,
      taskType:formModel.taskType,
      documents:this.task.documents
    };
  }

  private saveCompleted(task?: Task) {
    if (task) {
      this.task = task;
    }

    this.isSaving = false;
    this.alertService.stopLoadingMessage();

    this.resetForm(true);

    this.onTaskSaved.next(this.task);
  }

  private saveFailed(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'One or more errors occured while saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
    this.logger.log("Task Editor Component", "Error in task editor component", LogType.Error, error);
  }


  onProjectSelected($event:any){
    this.taskService.getAvailableDependents($event.value).subscribe((tasks: Task[]) => {
      this.dependentTaskList.unshift(...tasks);
    });

  }

  addTaskCreatedComment() {
    this.commentService.newComment(this.getNewCommentModel()).subscribe(x => {
    })
  }

  private getNewCommentModel(): TaskComment {
    return {
      taskId: Number(this.task.id),
      message: this.task.details,
      userId: this.authService.currentUser.id,
      commentType: 0
    };
  }

  checkValue(event) {
    if (event.target.value < 0) {
      event.target.value = 0;
    }
    this.calculateRemainingTime(event.target.value);
  }

  calculateRemainingTime(value?:any){
    const formModel = this.taskProfileForm.value;
    if(value){
      if(this.estimatedTime > value){
        var difference = this.estimatedTime - value;
        this.remainingTime = this.task.timeLeft - difference;
        if(this.remainingTime < 0){
          this.remainingTime = 0;
        }
      }
      if(value > this.estimatedTime){
        var difference = value - this.estimatedTime ;
        this.remainingTime = this.task.timeLeft + difference;
      }
      if(value == this.estimatedTime){
        this.remainingTime = this.task.timeLeft;
      }
    }
    else{
      if(this.estimatedTime > formModel.timeEstimate){
        var difference = this.estimatedTime - formModel.timeEstimate;
        this.remainingTime = this.task.timeLeft - difference;
        if(this.remainingTime < 0){
          this.remainingTime = 0;
        }
      }
      if(formModel.timeEstimate > this.estimatedTime){
        var difference = formModel.timeEstimate - this.estimatedTime ;
        this.remainingTime = this.task.timeLeft + difference;
      }
    }
  }

  onDependentTaskSelected($event:any){
    this.relatedTaskList = [];
    if($event.value > 0){
      this.taskService.getTaskRelationShips($event.value).subscribe(
          x => {
            this.relatedTaskList = x;
            if(isDevMode)
            console.log(this.relatedTaskList)
          },
          err => {
            if(isDevMode)
            console.log(err)
          }
      );
    }
  }

  get taskName() {
    return this.taskProfileForm.get('name');
  }

  get taskProjectId() {
    if(this.taskProfileForm.get('projectId').value){
      return true
    }
    return false;
  }


  get updateMailing(): string {
    return this.isEditMode ? 'auto' : 'always';
  }

  onNameExit(name:any){
    //append name to the details field
    //don't remove existing values if the user decided to fill out the details portion first
    this.details.setValue(this.details.value + " " + name);
  }

  get name() { return this.taskProfileForm.get('name'); }
  get notes() { return this.taskProfileForm.get('notes'); }
  get projectId() { return this.taskProfileForm.get('projectId'); }
  get taskStatus() { return this.taskProfileForm.get('taskStatus'); }
  get timeLeft() { return this.taskProfileForm.get('timeLeft'); }
  get timeEstimate() { return this.taskProfileForm.get('timeEstimate'); }
  get approver() { return this.taskProfileForm.get('approver'); }
  get assignedToId() { return this.taskProfileForm.get('assignedToId'); }
  get dueDate() { return this.taskProfileForm.get('dueDate'); }
  get details() { return this.taskProfileForm.get('details'); }

}
