import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { QuickTaskComponent } from '../quick-task/quick-task.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MediaMatcher } from '@angular/cdk/layout';
import { Task } from 'src/app/models/task.model';

@Component({
  selector: 'app-quick-task-dialog',
  templateUrl: './quick-task-dialog.component.html',
  styleUrls: ['./quick-task-dialog.component.scss']
})
export class QuickTaskDialogComponent implements OnInit {
  @ViewChild(QuickTaskComponent)
  quickTask: QuickTaskComponent;
  private _mobileQueryListener: () => void;
  mobileQuery: MediaQueryList;

  get name(): any {
    return this.data.task ? { name: this.data.task.name } : null;
  }

  constructor(
    public dialogRef: MatDialogRef<QuickTaskComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    @Inject(MAT_DIALOG_DATA) public data: { task: Task, edit:boolean }) {
      this.mobileQuery = media.matchMedia('(max-width: 600px)');
      this._mobileQueryListener = () => changeDetectorRef.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.quickTask.taskSaved$.subscribe(task => this.dialogRef.close(task));
  }

  cancel(): void {
    this.dialogRef.close(null);
  }
}
