import { Component, OnInit, ViewChild, Input, OnChanges, NgZone } from '@angular/core';
import { Subject } from 'rxjs';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OrganizationService } from 'src/app/services/organization.service';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { Organization } from 'src/app/models/organization.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BlobStorage } from 'src/app/services/blob-storage.service';
import { getBlueLogoUrl, States } from 'src/app/utilities';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-organization-editor',
  templateUrl: './organization-editor.component.html',
  styleUrls: ['./organization-editor.component.scss']
})

export class OrganizationEditorComponent implements OnChanges {

  @ViewChild('form', { static: true })
  private form: NgForm;
  panelOpenState = false;
  public isNewOrg = false;
  states = States;
  public isSaving = false;
  private onOrgSaved = new Subject<Organization>();
  disableAnimation = true;
  public sameAsMailing = false;
  fileUpload: any;
  fileToUpload: FormData;
  logoSource = null;
  url:any;
  currentPPIcon:any;
  currentPPName:any;

  @Input() organization: Organization = new Organization();
  @Input() isEditMode = false;

  orgProfileForm: FormGroup;
  orgSaved$ = this.onOrgSaved.asObservable();

  get orgName() {
    return this.orgProfileForm.get('name');
  }

  get floatLabels(): string {
    return this.isEditMode ? 'auto' : 'always';
  }

  constructor(
    private orgService: OrganizationService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private blobStorageService: BlobStorage,
    public utilitiesService: UtilitiesService,
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.logoSource = this.organization.logoUrl ? this.organization.logoUrl : getBlueLogoUrl();
  }

  ngOnChanges() {
    if (this.organization) {
      this.isNewOrg = false;
    } else {
      this.isNewOrg = true;
      this.organization = new Organization();
    }
    this.resetForm();
    this.setOrganizationValidators();
  }

  public setOrg(org?: Organization) {
    this.organization = org;
    this.ngOnChanges();
  }

  private buildForm() {
    this.orgProfileForm = this.formBuilder.group({
      fax: '',
      notes: '',
      logoUrl: '',
      address2: '',
      cellPhone: '',
      billToCity: '',
      billToName: '',
      billToName2: '',
      billToPhone: '',
      billToState: '',
      billToAddress: '',
      billToAddress2: '',
      billToPostalCode: '',
      city: ['', Validators.required],
      name: ['', Validators.required],
      state: ['', Validators.required],
      address: ['', Validators.required],
      ownerId: ['', Validators.required],
      postalCode: ['', Validators.required],
      phone: ['', [Validators.required, Validators.pattern(/^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/)]],
    });
  }

  public resetForm(stopEditing = false) {
    if (stopEditing) {
      this.isEditMode = false;
    }

    if (!this.organization) {
      this.isNewOrg = true;
      this.organization = new Organization();
    }

    this.orgProfileForm.reset({
      fax: this.organization.fax || '',
      city: this.organization.city || '',
      name: this.organization.name || '',
      notes: this.organization.notes || '',
      phone: this.organization.phone || '',
      state: this.organization.state || '',
      address: this.organization.address || '',
      ownerId: this.organization.ownerId || '',
      logoUrl: this.organization.logoUrl || '',
      address2: this.organization.address2 || '',
      cellPhone: this.organization.cellPhone || '',
      billToCity: this.organization.billToCity || '',
      billToName: this.organization.billToName || '',
      postalCode: this.organization.postalCode || '',
      billToName2: this.organization.billToName2 || '',
      billToPhone: this.organization.billToPhone || '',
      billToState: this.organization.billToState || '',
      billToAddress: this.organization.billToAddress || '',
      billToAddress2: this.organization.billToAddress2 || '',
      billToPostalCode: this.organization.billToPostalCode || '',
    });
  }

  //set conditional validation for the adminUserName
  setOrganizationValidators() {
    const ownerId = this.orgProfileForm.get("ownerId");
    if (this.isNewOrg == false) {
      ownerId.setValidators(null);
    }
  }

  public beginEdit() {
    this.isEditMode = true;
  }

  public save() {
    if (!this.form.submitted) {
      // Causes validation to update.
      this.form.onSubmit(null);
      return;
    }

    if (!this.orgProfileForm.valid) {
      this.alertService.showValidationError();
      return;
    }

    this.isSaving = true;
    // this.alertService.startLoadingMessage('Saving changes...');

    const editedOrg = this.getEditedOrg();

    if (this.isNewOrg) {
      this.orgService.newOrg(editedOrg).subscribe(
        org => this.saveCompleted(org),
        error => this.saveFailed(error));
    } else {
      this.orgService.updateOrg(editedOrg).subscribe(
        () => this.saveCompleted(editedOrg),
        error => this.saveFailed(error));
    }
  }

  public cancel() {
    this.resetForm();
    this.isEditMode = false;

    this.alertService.resetStickyMessage();
  }

  private getEditedOrg(): Organization {
    const formModel = this.orgProfileForm.value;

    return {
      fax: formModel.fax,
      city: formModel.city,
      name: formModel.name,
      notes: formModel.notes,
      phone: formModel.phone,
      state: formModel.state,
      id: this.organization.id,
      address: formModel.address,
      ownerId: formModel.ownerId,
      logoUrl: formModel.logoUrl,
      address2: formModel.address2,
      cellPhone: formModel.cellPhone,
      billToCity: formModel.billToCity,
      billToName: formModel.billToName,
      postalCode: formModel.postalCode,
      billToName2: formModel.billToName2,
      billToPhone: formModel.billToPhone,
      billToState: formModel.billToState,
      adminUserName: formModel.adminUserName,
      billToAddress: formModel.billToAddress,
      billToAddress2: formModel.billToAddress2,
      billToPostalCode: formModel.billToPostalCode,
    };
  }

  private saveCompleted(org?: Organization) {
    if (org) {
      this.organization = org;
    }

    this.isSaving = false;
    this.alertService.stopLoadingMessage();

    this.resetForm(true);

    this.onOrgSaved.next(this.organization);
  }

  private saveFailed(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'One or more errors occured while saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  //this prevents the expansion panels from animating while the dialog opens
  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => this.disableAnimation = false);
  }

  onCheckChanged(event: MatCheckboxChange) {
    //if checkbox is unchecked, clear the form, else populate the values
    this.sameAsMailing = event.checked;
    if (!this.sameAsMailing) {
      this.orgProfileForm.get('billToAddress').setValue("");
      this.orgProfileForm.get('billToAddress2').setValue("");
      this.orgProfileForm.get('billToPostalCode').setValue("");
      this.orgProfileForm.get('billToState').setValue("");
      this.orgProfileForm.get('billToName').setValue("");
      this.orgProfileForm.get('billToCity').setValue("");
      this.orgProfileForm.get('billToPhone').setValue("");
    }
    else {
      const Address = this.orgProfileForm.get('address').value;
      const Address2 = this.orgProfileForm.get('address2').value;
      const Name = this.orgProfileForm.get('name').value;
      const State = this.orgProfileForm.get('state').value;
      const City = this.orgProfileForm.get('city').value;
      const PostalCode = this.orgProfileForm.get('postalCode').value;
      const Phone = this.orgProfileForm.get('phone').value;
      // billing
      this.orgProfileForm.get('billToAddress').setValue(Address);
      this.orgProfileForm.get('billToAddress2').setValue(Address2);
      this.orgProfileForm.get('billToPostalCode').setValue(PostalCode);
      this.orgProfileForm.get('billToState').setValue(State);
      this.orgProfileForm.get('billToName').setValue(Name);
      this.orgProfileForm.get('billToCity').setValue(City);
      this.orgProfileForm.get('billToPhone').setValue(Phone);
    }
  }

  handleFileInput(event) {
    let formData: FormData = new FormData();
    formData.append("asset", event.target.files[0], event.target.files[0].name);
    this.fileToUpload = formData;

    this.blobStorageService.insertFile(this.fileToUpload, event.target.files[0].name).subscribe(
      data => {
        this.logoSource = data;
        this.orgProfileForm.patchValue({
          logoUrl: data
        });
      },
      error => { throw error },
  )
  }
}
