import { catchError } from 'rxjs/operators';
import { EndpointBase } from './endpoint-base.service';
import { AuthService } from './auth.service';
import { ConfigurationService } from './configuration.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LogType } from '../models/log-type.model';

class EventLog{
    public message:string;
    public logType:LogType;
    public source: string;
    public data: string;
}

@Injectable()
export class LogService extends EndpointBase {

    get logsUrl() { return this.configurations.baseUrl + '/api/eventlog'; }

    constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
        super(http, authService);
    }

    log(msg: any, source:any, type:LogType, data:any) {
        var log = new EventLog();
        log.data = data;
        log.message = msg;
        log.source = source;
        log.logType = type;
        this.logToApi(log).subscribe(x => {

        })
    }
    
    public logToApi(log:EventLog) {
        return this.http.post(this.logsUrl, JSON.stringify(log), this.requestHeaders);
    };

}

