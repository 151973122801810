import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from './configuration.service';

@Injectable()
export class BlobStorage extends EndpointBase {
    get baseUrl() { return this.configurations.baseUrl + '/api/blobstorage'; }
    files: any;

    constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
        super(http, authService);
    }

    public uploadProfilePicture(fileupload: any, userName:any){
        return this.http.post(this.baseUrl + '/uploadAvatar/' + userName, fileupload, { responseType: "text" })
    }

    public insertFile(fileupload: any, taskId:number) {
        return this.http.post(this.baseUrl + '/insertfile/' + taskId, fileupload, { responseType: "text" })
    };

    public insertFiles(fileupload: any, taskId:number) {
        return this.http.post<string[]>(this.baseUrl + '/insertfiles/' + taskId, fileupload)
    };

    showBlobs(taskId:number) {
        return this.http.get<string[]>(this.baseUrl + '/listfiles/' + taskId);
    }

    public deleteFile(prefix:string, fileName:string){
       return this.http.get(`${this.baseUrl}/deletefile/${prefix}/${fileName}`);
    }
}
