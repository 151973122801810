import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from './configuration.service';

@Injectable()
export class CommentsEndpoint extends EndpointBase {
    get commentsUrl() { return this.configurations.baseUrl + '/api/comments'; }
    get commentsPublicUrl() { return this.configurations.baseUrl + '/api/public/comments'; }
    get updateCommentUrl() { return this.configurations.baseUrl + '/api/public/comments/updateComment'; }
    get markAsReadUrl() { return this.configurations.baseUrl + '/api/comments/markAsRead'; }


    constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
        super(http, authService);
    }

    getCommentsByTaskIdEndpoint<T>(taskId: number): Observable<T> {
      const endpointUrl = `${this.commentsUrl}/${taskId}`

      return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
        catchError(error => {
          return this.handleError(error, () => this.getCommentsByTaskIdEndpoint(taskId));
        }));
    }

    getCommentsByProjectIdEndpoint<T>(projectId: number, taskId:number): Observable<T> {
      const endpointUrl = `${this.commentsUrl}/${projectId}/${taskId}`

      return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
        catchError(error => {
          return this.handleError(error, () => this.getCommentsByProjectIdEndpoint(projectId, taskId));
        }));
    }

    getNewCommentEndpoint<T>(commentObject: any): Observable<T> {
        const endpointUrl = this.commentsUrl

        return this.http.post<T>(endpointUrl, JSON.stringify(commentObject), this.requestHeaders).pipe<T>(
          catchError(error => {
            return this.handleError(error, () => this.getNewCommentEndpoint(commentObject));
          }));
      }

    getComments<T>(id: number): Observable<T> {
      const endpointUrl = `${this.commentsUrl}/${id}`

      return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
        catchError(error => {
          return this.handleError(error, () => this.getComments(id));
          }));
      }

      deleteCommentEndpoint<T>(commentId: number): Observable<T> {
        const endpointUrl = `${this.commentsUrl}/${commentId}`
        return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
          catchError(error => {
            return this.handleError(error, () => this.deleteCommentEndpoint(commentId));
          }));
      }

      updateCommentEndpoint<T>(commentObject:any): Observable<T> {
        const endpointUrl = `${this.commentsUrl}`
        return this.http.put<T>(endpointUrl, JSON.stringify(commentObject), this.requestHeaders).pipe<T>(
          catchError(error => {
            return this.handleError(error, () => this.updateCommentEndpoint(commentObject));
          }));
      }

      markAsReadEndpoint<T>(taskId:number): Observable<T> {
        const endpointUrl = `${this.markAsReadUrl}/${taskId}`
        return this.http.put<T>(endpointUrl, null, this.requestHeaders).pipe<T>(
          catchError(error => {
            return this.handleError(error, () => this.markAsReadEndpoint(taskId));
          }));
      }

  }
