import { Task } from 'src/app/models/task.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';;
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from './configuration.service';

@Injectable()
export class TaskEndpoint extends EndpointBase {
  get tasksUrl() { return this.configurations.baseUrl + '/api/tasks'; }
  get tasksPublicUrl() { return this.configurations.baseUrl + '/api/public/tasks'; }
  get taskByNameUrl() { return this.configurations.baseUrl + '/api/tasks/name'; }
  get taskByIdUrl() { return this.configurations.baseUrl + '/api/tasks'; }
  get taskByProjectIdUrl() { return this.configurations.baseUrl + '/api/tasks/getTasksByProjectId'; }
  get existingHelpTaskUrl() { return this.configurations.baseUrl + '/api/tasks/getHelpTasksByParentId'; }
  get tasksImportUrl() { return this.configurations.baseUrl + '/api/tasks/importTasks'; }
  get queueUrl() { return this.configurations.baseUrl + '/api/tasks/GetQueue'; }

  constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
      super(http, authService);
  }

  getTasksEndpoint<T>(page?: number, pageSize?: number): Observable<T> {
  const endpointUrl = page && pageSize ? `${this.tasksUrl}/${page}/${pageSize}` : this.tasksUrl;

  return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
      return this.handleError(error, () => this.getTasksEndpoint(page, pageSize));
      }));
  }

  getAvailableDependentsEndpoint<T>(projectId): Observable<T> {
    // const endpointUrl = page && pageSize ? `${this.tasksUrl}/${page}/${pageSize}` : this.tasksUrl;
    const endpointUrl = `${this.tasksUrl}/GetAvailableDependents/${projectId}`;
    console.log(endpointUrl)
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
        catchError(error => {
        return this.handleError(error, () => this.getTasksEndpoint(projectId));
        }));
    }

  getTaskByNameEndpoint<T>(taskName: string): Observable<T> {
      const endpointUrl = `${this.taskByNameUrl}/${taskName}`;

      return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
        catchError(error => {
          return this.handleError(error, () => this.getTaskByNameEndpoint(taskName));
        }));
    }

  getNewTaskEndpoint<T>(taskObject: any, isPublicRegistration?: boolean): Observable<T> {
      const endpointUrl = isPublicRegistration ? this.tasksPublicUrl : this.tasksUrl;

      return this.http.post<T>(endpointUrl, JSON.stringify(taskObject), this.requestHeaders).pipe<T>(
        catchError(error => {
          return this.handleError(error, () => this.getNewTaskEndpoint(taskObject));
        }));
    }

  createHelpTaskEndpoint<T>(taskObject: any): Observable<T> {
    const endpointUrl = this.tasksUrl + '/helpTask/';

    return this.http.post<T>(endpointUrl, JSON.stringify(taskObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewTaskEndpoint(taskObject));
      }));
  }

  getImportTasksEndpoint<T>(projectId: number, formData: any): Observable<T> {
      const endpointUrl = `${this.tasksImportUrl}/${projectId}`;

      return this.http.post<T>(endpointUrl, formData, this.requestFileUploadHeaders).pipe<T>(
        catchError(error => {
          return this.handleError(error, () => this.getImportTasksEndpoint(projectId, formData));
        }));
    }

  getTaskByIdEndpoint<T>(taskId: number): Observable<T> {
      const endpointUrl = `${this.tasksUrl}/${taskId}`;

      return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
        catchError(error => {
          return this.handleError(error, () => this.getTaskByIdEndpoint(taskId));
        }));
  }

  getTaskByProjectIdEndpoint<T>(projectId: number): Observable<T> {
    const endpointUrl = `${this.taskByProjectIdUrl}/${projectId}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getTaskByIdEndpoint(projectId));
      }));
  }

  getUpdateTaskEndpoint<T>(taskObject: Task, taskId: number): Observable<T> {
    const endpointUrl = this.tasksUrl + '/' + taskId;
    return this.http.put<T>(endpointUrl, JSON.stringify(taskObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        console.log(error);
        return this.handleError(error, () => this.getUpdateTaskEndpoint(taskObject, taskId));
      }));
  }

  getSubmitTaskEndpoint<T>(taskId: number): Observable<T> {
    const endpointUrl = this.tasksUrl + '/submittask/' + taskId;
    return this.http.put<T>(endpointUrl, JSON.stringify(taskId), this.requestHeaders).pipe<T>(
      catchError(error => {
        console.log(error);
        return this.handleError(error, () => this.getSubmitTaskEndpoint(taskId));
      }));
  }

  getReviseTaskEndpoint<T>(taskId: number): Observable<T> {
    const endpointUrl = this.tasksUrl + '/revisetask/' + taskId;
    return this.http.put<T>(endpointUrl, JSON.stringify(taskId), this.requestHeaders).pipe<T>(
      catchError(error => {
        console.log(error);
        return this.handleError(error, () => this.getReviseTaskEndpoint(taskId));
      }));
  }

  getApproveTaskEndpoint<T>(taskId: number): Observable<T> {
    const endpointUrl = this.tasksUrl + '/approvetask/' + taskId;
    return this.http.put<T>(endpointUrl, JSON.stringify(taskId), this.requestHeaders).pipe<T>(
      catchError(error => {
        console.log(error);
        return this.handleError(error, () => this.getApproveTaskEndpoint(taskId));
      }));
  }

  getArchiveTaskEndpoint<T>(taskId: number): Observable<T> {
    const endpointUrl = this.tasksUrl + '/archivetask/' + taskId;
    return this.http.put<T>(endpointUrl, JSON.stringify(taskId), this.requestHeaders).pipe<T>(
      catchError(error => {
        console.log(error);
        return this.handleError(error, () => this.getArchiveTaskEndpoint(taskId));
      }));
  }

  getUpdateTaskDocumentsEndpoint<T>(taskObject: Task, taskId: number): Observable<T> {
    return this.http.put<T>(this.tasksUrl + '/UpdateTaskDocuments/' + taskId, JSON.stringify(taskObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        console.log(error);
        return this.handleError(error, () => this.getUpdateTaskDocumentsEndpoint(taskObject, taskId));
      }));
  }

  getCheckForExistingHelpTaskEndpoint<T>(parentId: number): Observable<T> {
    const endpointUrl = `${this.tasksUrl}/${true}/${parentId}`
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getTaskByIdEndpoint(parentId));
      }));
  }

  getDeleteTaskEndpoint<T>(taskId: number): Observable<T> {
    const endpointUrl = `${this.tasksUrl}/${taskId}`;

    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteTaskEndpoint(taskId));
      }));
  }

  getQueueEndpoint<T>(page?: number, pageSize?: number): Observable<T> {
    const endpointUrl = page && pageSize ? `${this.queueUrl}/${page}/${pageSize}` : this.queueUrl;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
        catchError(error => {
        return this.handleError(error, () => this.getQueueEndpoint(page, pageSize));
        }));
    }

    getTaskRelationShipsEndpoint<T>(taskId: any): Observable<T> {
      const endpointUrl = `${this.tasksUrl}/getTaskRelationShips/${taskId}`
      return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
        catchError(error => {
          return this.handleError(error, () => this.getTaskRelationShipsEndpoint(taskId));
        }));
    }

}
