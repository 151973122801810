import { MediaMatcher } from '@angular/cdk/layout';
import { Component, OnInit, AfterViewInit, ViewChild, Inject, ChangeDetectorRef } from '@angular/core';
import { Organization } from 'src/app/models/organization.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrganizationEditorComponent } from '../organization-editor/organization-editor.component';

@Component({
  selector: 'app-edit-organization-dialog',
  templateUrl: './edit-organization-dialog.component.html',
  styleUrls: ['./edit-organization-dialog.component.scss']
})
export class EditOrganizationDialogComponent implements AfterViewInit {

  @ViewChild(OrganizationEditorComponent, { static: true })
  editOrg: OrganizationEditorComponent;
  private _mobileQueryListener: () => void;
  mobileQuery: MediaQueryList;
  get name(): any {
    return this.data.organization ? { name: this.data.organization.name } : null;
  }

  constructor(
    public dialogRef: MatDialogRef<EditOrganizationDialogComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    @Inject(MAT_DIALOG_DATA) public data: { organization: Organization }) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngAfterViewInit() {
    this.editOrg.orgSaved$.subscribe(org => this.dialogRef.close(org));
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

}
