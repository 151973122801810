import { CheckEmailDialogContent } from './../../../models/check-email.model';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import {ViewEncapsulation} from '@angular/core';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-check-email',
  templateUrl: './check-email.component.html',
  styleUrls: ['./check-email.component.scss']
})


export class CheckEmailComponent implements OnInit {

  // emailContent: CheckEmailDialogContent;
  public emailHeader:any;
  public emailSubheader:any;
  public emailContent:any;
  public emailAddress:any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { content : CheckEmailDialogContent }, private dialog: MatDialog) {
      // this.emailHeader = data.content.emailHeader;
      // this.emailSubheader = data.content.emailSubHeader;
      // this.emailContent = data.content.emailContent;
      this.emailAddress = data.content.emailAddress;
  }

  ngOnInit(): void {
  }

  close(){
    this.dialog.closeAll();
  }

}