import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Organization } from '../models/organization.model';
import { AuthService } from './auth.service';
import { OrganizationEndpoint } from './organization-endpoint.service';
import { PermissionValues } from '../models/permission.model';

@Injectable()
export class OrganizationService {

  constructor(
    private authService: AuthService,
    private orgEndpoint: OrganizationEndpoint
  ) { }


  getCurrentOrg() {
    var orgId = this.authService.currentUser.organizationId;
    return this.orgEndpoint.getOrgByIdEndpoint(orgId);
  }

  getOrgs(page?: number, pageSize?: number) {
    return this.orgEndpoint.getOrgsEndpoint<Organization[]>(page, pageSize);
  }

  updateOrg(org) {
    if (org.id) {
      return this.orgEndpoint.getUpdateOrgEndpoint(org, org.id);
    } else {
      return this.orgEndpoint.getOrgByNameEndpoint<Organization>(org.name).pipe(
        mergeMap(foundOrg => {
          org.id = foundOrg.id;
          return this.orgEndpoint.getUpdateOrgEndpoint(org, org.id);
        }));
    }
  }

  newOrg(org) {
    return this.orgEndpoint.getNewOrgEndpoint<Organization>(org);
  }

  deleteOrg(orgOrOrgId: string | Organization): Observable<Organization> {
    if (typeof orgOrOrgId === 'string' || orgOrOrgId instanceof String) {
      return this.orgEndpoint.getDeleteOrgEndpoint<Organization>(orgOrOrgId as unknown as number);
    } else {
      if (orgOrOrgId.id) {
        return this.deleteOrg(orgOrOrgId.id.toString());
      } else {
        return this.orgEndpoint.getOrgByNameEndpoint<Organization>(orgOrOrgId.name).pipe<Organization>(
          mergeMap(customer => this.deleteOrg(customer.id.toString())));
      }
    }
  }

  userHasPermission(permissionValue: PermissionValues): boolean {
    return this.permissions.some(p => p === permissionValue);
  }

  get permissions(): PermissionValues[] {
    return this.authService.userPermissions;
  }
}
