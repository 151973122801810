import { LogType } from './../../models/log-type.model';
import { LogService } from './../../services/log.service';
// =============================
// Email: info@fastfoundry.com
// www.fastfoundry.com
// =============================

import { Component, OnInit, OnDestroy, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';

import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AuthService } from '../../services/auth.service';
import { ConfigurationService } from '../../services/configuration.service';
import { Utilities } from '../../services/utilities';
import { UserLogin } from 'src/app/models/user.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-control',
  templateUrl: './login-control.component.html',
  styleUrls: ['./login-control.component.scss']
})
export class LoginControlComponent implements OnInit, OnDestroy {
  isLoading = false;
  isGoogleLogin = false;
  isFacebookLogin = false;
  isTwitterLogin = false;
  modalClosedCallback: () => void;
  loginStatusSubscription: any;

  loginForm: FormGroup;
  updatePasswordForm: FormGroup;
  @ViewChild('form', { static: true })
  private form: NgForm;

  @Input()
  isModal = false;

  @Output()
  enterKeyPress = new EventEmitter();

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private configurations: ConfigurationService,
    private logger: LogService,
    private formBuilder: FormBuilder,
    private router: Router) {
    this.buildForm();
  }

  ngOnInit() {
    this.loginForm.setValue({
      userName: '',
      password: '',
      rememberMe: this.authService.rememberMe
    });

    if (this.getShouldRedirect()) {
      this.authService.redirectLoginUser();
    } else {
      this.loginStatusSubscription = this.authService.getLoginStatusEvent()
        .subscribe(isLoggedIn => {
          if (this.getShouldRedirect()) {
            this.authService.redirectLoginUser();
          }
        });
    }
  }

  ngOnDestroy() {
    if (this.loginStatusSubscription) {
      this.loginStatusSubscription.unsubscribe();
    }
  }

  buildForm() {
    this.loginForm = this.formBuilder.group({
      userName: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: ''
    });
  }

  get userName() { return this.loginForm.get('userName'); }

  get password() { return this.loginForm.get('password'); }

  getShouldRedirect() {
    return !this.isModal && this.authService.isLoggedIn && !this.authService.isSessionExpired;
  }

  closeModal() {
    if (this.modalClosedCallback) {
      this.modalClosedCallback();
    }
  }

  getUserLogin(): UserLogin {
    const formModel = this.loginForm.value;
    return new UserLogin(formModel.userName, formModel.password, formModel.rememberMe);
  }

  login() {
    if (!this.form.submitted) {
      this.form.onSubmit(null);
      return;
    }

    if (!this.loginForm.valid) {
      this.alertService.showValidationError();
      return;
    }

    this.isLoading = true;
    // this.alertService.startLoadingMessage('', 'Attempting login...');

    this.authService.loginWithPassword(this.getUserLogin())
      .subscribe(
        user => {

        },
        error => {
          this.alertService.stopLoadingMessage();

          if (Utilities.checkNoNetwork(error)) {
            this.alertService.showStickyMessage(Utilities.noNetworkMessageCaption, Utilities.noNetworkMessageDetail, MessageSeverity.error, error);
            this.offerAlternateHost();
          } else {
            const errorMessage = Utilities.getHttpResponseMessage(error);

            if (errorMessage) {
              this.alertService.showStickyMessage('Unable to login', this.mapLoginErrorMessage(errorMessage), MessageSeverity.error, error);
            } else {
              this.alertService.showStickyMessage('Unable to login', 'An error occured, please try again later.\nError: ' + Utilities.getResponseBody(error), MessageSeverity.error, error);
            }
            // this.logger.log("Login-control component", "Error attempting to login", LogType.Error, errorMessage);
          }
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        });
  }


  offerAlternateHost() {

    if (Utilities.checkIsLocalHost(location.origin) && Utilities.checkIsLocalHost(this.configurations.baseUrl)) {

      const apiUrl = prompt('It appears your backend Web API service is not running...\n' +
        'Would you want to temporarily switch to the online Demo API below?(Or specify another)', this.configurations.fallbackBaseUrl);

      if (apiUrl) {
        this.configurations.baseUrl = apiUrl;
        this.configurations.tokenUrl = apiUrl;
        this.alertService.showStickyMessage('API Changed!', 'The target Web API has been changed to: ' + apiUrl, MessageSeverity.warn);
      }
    }
  }


  mapLoginErrorMessage(error: string) {

    if (error === 'invalid_username_or_password') {
      return 'Invalid username or password';
    }

    if (error === 'invalid_grant') {
      return 'This account has been disabled';
    }

    return error;
  }

  enterKeyDown() {
    this.enterKeyPress.emit();
  }


  updatePassword() {
    if (!this.updatePasswordForm.valid) {
      this.alertService.showValidationError();
      return;
    }

    this.isLoading = true;
    this.alertService.startLoadingMessage('', 'Updating password...');

    const { currentPassword, newPassword } = this.updatePasswordForm.value;

    // this.accountService.updateUserPassword(this.userId, currentPassword, newPassword)
    //   .subscribe(
    //     () => {
    //       this.alertService.stopLoadingMessage();
    //       this.alertService.showMessage('Password Updated', 'Your password has been updated successfully', MessageSeverity.success);
    //     },
    //     error => {
    //       this.alertService.stopLoadingMessage();
    //       this.isLoading = false;
    //       this.alertService.showStickyMessage('Password Update Failed', 'Failed to update password', MessageSeverity.error, error);
    //     }
    //   );
  }

  passwordMatchValidator(formGroup: FormGroup) {
    return formGroup.get('newPassword').value === formGroup.get('confirmPassword').value
      ? null : { 'mismatch': true };
  }

  buildUpdatePasswordForm() {
    this.updatePasswordForm = this.formBuilder.group({
      currentPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required]]
    }, { validator: this.passwordMatchValidator });
  }
}


