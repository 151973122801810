import { MediaMatcher } from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OrganizationService } from 'src/app/services/organization.service';
import { States } from 'src/app/utilities';
import { CheckEmailDialogContent } from '../../models/check-email.model';
import { Registration } from '../../models/registration.model';
import { AccountService } from '../../services/account.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AuthService } from '../../services/auth.service';
import { ConfigurationService } from '../../services/configuration.service';
import { Utilities } from '../../services/utilities';
import { EqualValidator } from '../../shared/validators/equal.validator';
import { CheckEmailComponent } from '../check-email/check-email/check-email.component';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
  isLoading = false;
  loginStatusSubscription: any;
  private _mobileQueryListener: () => void;
  mobileQuery: MediaQueryList;
  @ViewChild('form', { static: true })
  private form: NgForm;
  states = States;
  @Input() isEditMode = false;

  public sameAsMailing = false;
  emailExists = false;
  billingFormGroup: FormGroup;
  mailingFormGroup: FormGroup;
  userFormGroup: FormGroup;

  get floatLabels(): string {
    return this.isEditMode ? 'auto' : 'always';
  }

  get updateMailing(): string {
    return this.isEditMode ? 'auto' : 'always';
  }

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private accountService: AccountService,
    private configurations: ConfigurationService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private _router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private organizationService: OrganizationService) {
    this.buildForm();
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.userFormGroup.setValue({
      companyName: '',
      email: '',
      // password: {
      //   newPassword: '',
      //   confirmPassword: ''
      // }
    });

    this.mailingFormGroup.setValue({
      name: '',
      phone: '',
      cellPhone: '',
      fax: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      postalCode: ''
    });

    this.formBuilder.group({
      billToName: '',
      billToName2: '',
      billToAddress: '',
      billToAddress2: '',
      billToCity: '',
      billToPostalCode: '',
      billToState: '',
      billToPhone: ''
    });

    if (this.getShouldRedirect()) {
      this.authService.redirectLoginUser();
    } else {
      this.loginStatusSubscription = this.authService.getLoginStatusEvent()
        .subscribe(() => {
          if (this.getShouldRedirect()) {
            this.authService.redirectLoginUser();
          }
        });
    }
  }

  ngOnDestroy() {
    if (this.loginStatusSubscription) {
      this.loginStatusSubscription.unsubscribe();
    }
  }

  buildForm() {
    this.userFormGroup = this.formBuilder.group({
      companyName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      // password: this.formBuilder.group({
      //   newPassword: ['', [Validators.required, Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,}/)]],
      //   confirmPassword: ['', [Validators.required, EqualValidator('newPassword')]]
      // })
    });

    this.mailingFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      phone: ['', [Validators.required, Validators.pattern(/^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/)]],
      cellPhone: '',
      fax: '',
      address: ['', Validators.required],
      address2: '',
      city: ['', Validators.required],
      state: ['', Validators.required],
      postalCode: ['', Validators.required],
    });
    this.billingFormGroup = this.formBuilder.group({
      billToName: ['', Validators.required],
      billToName2: '',
      billToAddress: ['', Validators.required],
      billToAddress2: '',
      billToCity: ['', Validators.required],
      billToPostalCode: ['', Validators.required],
      billToState: ['', Validators.required],
      billToPhone: ['', [Validators.required, Validators.pattern(/^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/)]],
    });
  }

  onCheckChanged(event: MatCheckboxChange) {
    // if checkbox is unchecked, clear the form, else populate the values
    this.sameAsMailing = event.checked;
    if (!this.sameAsMailing) {
      this.billingFormGroup.get('billToName').setValue('');
      this.billingFormGroup.get('billToPhone').setValue('');
      this.billingFormGroup.get('billToAddress').setValue('');
      this.billingFormGroup.get('billToAddress2').setValue('');
      this.billingFormGroup.get('billToCity').setValue('');
      this.billingFormGroup.get('billToState').setValue('');
      this.billingFormGroup.get('billToPostalCode').setValue('');
    }
    else {
      const Name = this.mailingFormGroup.get('name').value;
      const Phone = this.mailingFormGroup.get('phone').value;
      const CellPhone = this.mailingFormGroup.get('cellPhone').value;
      const Fax = this.mailingFormGroup.get('fax').value;
      const Address = this.mailingFormGroup.get('address').value;
      const Address2 = this.mailingFormGroup.get('address2').value;
      const State = this.mailingFormGroup.get('state').value;
      const City = this.mailingFormGroup.get('city').value;
      const PostalCode = this.mailingFormGroup.get('postalCode').value;
      // billing
      this.billingFormGroup.get('billToName').setValue(Name);
      this.billingFormGroup.get('billToPhone').setValue(Phone);
      this.billingFormGroup.get('billToAddress').setValue(Address);
      this.billingFormGroup.get('billToAddress2').setValue(Address2);
      this.billingFormGroup.get('billToCity').setValue(City);
      this.billingFormGroup.get('billToState').setValue(State);
      this.billingFormGroup.get('billToPostalCode').setValue(PostalCode);
    }
  }

  get companyName() { return this.userFormGroup.get('companyName') }
  get email() { return this.userFormGroup.get('email'); }
  get newPassword() { return this.userFormGroup.get('password').get('newPassword'); }
  get confirmPassword() { return this.userFormGroup.get('password').get('confirmPassword'); }

  getShouldRedirect() {
    return this.authService.isLoggedIn && !this.authService.isSessionExpired;
  }

  getNewRegistration(): Registration {
    const userInfo = this.userFormGroup.value;
    const mailingInfo = this.mailingFormGroup.value;
    const billingInfo = this.billingFormGroup.value;
    const newRegistration = new Registration();

    //userinfo
    newRegistration.companyName = userInfo.companyName;
    newRegistration.userName = userInfo.email; // username is email
    //we will auto generate this now
    // newRegistration.password = userInfo.password.newPassword;
    // newRegistration.confirmPassword = userInfo.password.confirmPassword;
    newRegistration.email = userInfo.email;

    //mailing info
    newRegistration.name = mailingInfo.name;
    newRegistration.phone = mailingInfo.phone;
    newRegistration.cellPhone = mailingInfo.cellPhone;
    newRegistration.fax = mailingInfo.fax;
    newRegistration.address = mailingInfo.address;
    newRegistration.address2 = mailingInfo.address2;
    newRegistration.city = mailingInfo.city;
    newRegistration.state = mailingInfo.state;
    newRegistration.postalCode = mailingInfo.postalCode;

    //billing info
    newRegistration.billToName = billingInfo.billToName;
    newRegistration.billToPhone = billingInfo.billToPhone;
    newRegistration.billToAddress = billingInfo.billToAddress;
    newRegistration.billToAddress2 = billingInfo.billToAddress2;
    newRegistration.billToCity = billingInfo.billToCity;
    newRegistration.billToState = billingInfo.billToState;
    newRegistration.billToPostalCode = billingInfo.billToPostalCode;

    return newRegistration;
  }

  register() {
    if (!this.userFormGroup.valid) {
      this.alertService.showValidationError();
      return;
    }

    if (!this.mailingFormGroup.valid) {
      this.alertService.showValidationError();
      return;
    }

    if (!this.billingFormGroup.valid) {
      this.alertService.showValidationError();
      return;
    }

    this.isLoading = true;
    this.alertService.startLoadingMessage('', 'Registering new account...');
    const user = this.getNewRegistration();
    this.accountService.newRegistration(user).subscribe(
      newUser => this.saveSuccessHelper(user),
      error => this.saveFailedHelper(error)
    )
    // var user = new Registration();
    // user.email = "drew@fastfoundry.com";
    // user.companyName = "Fast Foundry";
    // user.userName = "drew@fastfoundry.com"
    // // user.password = "Passw0rd!";
    // // user.confirmPassword = "Passw0rd!";
    // user.name = "Drew";
    // user.phone = "123-456-7890";
    // user.cellPhone = "123-456-7890";
    // user.fax = "123-456-7890";
    // user.address = "123 Main St";
    // user.address2 = "Apt 1";
    // user.city = "Anytown";
    // user.state = "CA";
    // user.postalCode = "12345";
    // user.billToName = "Drew";
    // user.billToPhone = "123-456-7890";
    // user.billToAddress = "123 Main St";
    // user.billToAddress2 = "Apt 1";
    // user.billToCity = "Anytown";
    // user.billToState = "CA";
    // user.billToPostalCode = "12345";
    // this.accountService.newRegistration(user).subscribe(
    //   newUser => this.saveSuccessHelper(user),
    //   error => this.saveFailedHelper(error)
    // )
  }

  private saveSuccessHelper(newuser: Registration) {
    this.alertService.stopLoadingMessage();
    // this.alertService.showMessage('Success', `User account "${newuser.userName}" was created successfully`, MessageSeverity.success);
    this.checkEmailDialog(newuser.email);
    //probably shouldn't auto login? Have them check email for confirmation email first -d.s
    // this.login( newuser.userName, newuser.password);
  }

  private saveFailedHelper(error: any) {
    this.isLoading = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'The below errors occured during registration:', MessageSeverity.error, error);
    console.log(error)
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  // make sure there isn't an already existing account with that email -d.s
  public checkIfEmailExists(event: any) {
    var potentialEmail = event.target.value;
    this.accountService.doesUserEmailExist(potentialEmail).subscribe(
      response => this.throwEmailValidationError(response),
    )
  }

  public throwEmailValidationError(response: any) {
    // reset bool to false, this combined with an ngif will show our errors if there are any
    this.emailExists = false;
    // if account exists
    if (response == true) {
      this.emailExists = true;
      // flag email to have errors so that the <mat error> activates
      this.userFormGroup.controls['email'].setErrors({ 'incorrect': true });
    }
  }

  login(username: string, password: string) {
    this.isLoading = true;
    this.alertService.startLoadingMessage('', 'Attempting login...');

    this.authService.loginWithPassword({ userName: username, password, rememberMe: false })
      .subscribe(
        user => {
          setTimeout(() => {
            this.alertService.stopLoadingMessage();
            this.isLoading = false;
            this.userFormGroup.reset();

            //this.alertService.showMessage('Login', `Welcome ${user.userName}!`, MessageSeverity.success);
            this.alertService.showStickyMessage('', 'Your account was created successfully', MessageSeverity.success);
          }, 500);
        },
        error => {
          this.alertService.stopLoadingMessage();

          if (Utilities.checkNoNetwork(error)) {
            this.alertService.showStickyMessage(Utilities.noNetworkMessageCaption, Utilities.noNetworkMessageDetail, MessageSeverity.error, error);
          } else {
            const errorMessage = Utilities.getHttpResponseMessage(error);

            if (errorMessage) {
              this.alertService.showStickyMessage('Unable to login', this.mapLoginErrorMessage(errorMessage), MessageSeverity.error, error);
            } else {
              this.alertService.showStickyMessage('Unable to login', 'An error occured while logging in, please try again later.\nError: ' + Utilities.getResponseBody(error), MessageSeverity.error, error);
            }
          }
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        });
  }

  public checkEmailDialog(email?:any) {

    var content = new CheckEmailDialogContent(email);

    const dialogRef = this.dialog.open(CheckEmailComponent,
      {
        width: this.mobileQuery.matches? '90vw' : '50vw',
        maxWidth : '90vw',
        autoFocus: false,
        data: { content },
      });
    dialogRef.afterClosed().subscribe(result => {
      this._router.navigate(['login']);
    });


    // setTimeout(() => {
    //     dialogRef.close();
    //     this._router.navigateByUrl('/login');
    // }, 2000);
  }

  mapLoginErrorMessage(error: string) {

    if (error === 'invalid_username_or_password') {
      return 'Invalid username or password';
    }

    if (error === 'invalid_grant') {
      return 'This account has been disabled';
    }

    return error;
  }
}
