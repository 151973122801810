import { Component, OnInit, AfterViewInit, ViewChild, Inject, ChangeDetectorRef } from '@angular/core';
import { TaskEditorComponent } from '../task-editor/task-editor.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Task } from 'src/app/models/task.model';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-edit-task-dialog',
  templateUrl: './edit-task-dialog.component.html',
  styleUrls: ['./edit-task-dialog.component.scss']
})
export class EditTaskDialogComponent implements AfterViewInit {
  @ViewChild(TaskEditorComponent, { static: true })
  editTask: TaskEditorComponent;
  private _mobileQueryListener: () => void;
  mobileQuery: MediaQueryList;

  get name(): any {
    return this.data.task ? { name: this.data.task.name } : null;
  }

  constructor(
    public dialogRef: MatDialogRef<EditTaskDialogComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    @Inject(MAT_DIALOG_DATA) public data: { task: Task, edit:boolean }) {
      this.mobileQuery = media.matchMedia('(max-width: 600px)');
      this._mobileQueryListener = () => changeDetectorRef.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngAfterViewInit() {
    this.editTask.taskSaved$.subscribe(task => this.dialogRef.close(task));
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

}
