// =============================
// Email: info@fastfoundry.com
// www.fastfoundry.com
// =============================
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { LinkyModule } from 'ngx-linky';
import { OAuthModule } from 'angular-oauth2-oidc';
import { ToastaModule } from 'ngx-toasta';
import { AppRoutingModule } from './app-routing.module';
import { AppErrorHandler } from './app-error.handler';
import { SharedModule } from './shared/shared.module';
import { FooterModule } from './shared/footer/footer.component';
import { ThemePickerModule } from './shared/theme-picker/theme-picker.component';
import { BlobStorage } from './services/blob-storage.service';
import { AppTitleService } from './services/app-title.service';
import { AppTranslationService, TranslateLanguageLoader } from './services/app-translation.service';
import { ConfigurationService } from './services/configuration.service';
import { AlertService } from './services/alert.service';
import { LocalStoreManager } from './services/local-store-manager.service';
import { OidcHelperService } from './services/oidc-helper.service';
import { NotificationService } from './services/notification.service';
import { NotificationEndpoint } from './services/notification-endpoint.service';
import { AccountService } from './services/account.service';
import { AccountEndpoint } from './services/account-endpoint.service';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { LoginControlComponent } from './components/login/login-control.component';
import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component';
import { RegisterComponent } from './components/register/register.component';
import { ConfirmEmailComponent } from './components/confirm-email/confirm-email.component';
import { RecoverPasswordComponent } from './components/recover-password/recover-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { CheckEmailComponent } from './components/check-email/check-email/check-email.component';
import { OrganizationService } from './services/organization.service';
import { OrganizationEndpoint } from './services/organization-endpoint.service';
import { NgxMaskModule } from 'ngx-mask';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommentsService } from './services/comments.service';
import { CommentsEndpoint } from './services/comments-endpoint.service';
import { TimeTrackingService } from './services/timetracking.service';
import { TimeTrackingEndpoint } from './services/timetracking-endpoint.service';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog/confirmation-dialog.component';
import { TaskService } from './services/task.service';
import { TaskEndpoint } from './services/task-endpoint.service';
import { ProjectService } from './services/project.service';
import { ProjectEndpoint } from './services/project-endpoint.service';
import { LogService } from './services/log.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterExtService } from './services/router-extension.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { UtilitiesService } from './services/utilities.service';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

@NgModule({
    imports: [
        SharedModule,
        MatProgressSpinnerModule,
        FooterModule,
        ThemePickerModule,
        HttpClientModule,
        AppRoutingModule,
        OAuthModule.forRoot(),
        ToastaModule.forRoot(),
        NgxMaskModule.forRoot(),
        TranslateModule.forRoot({
          loader : {
            provide: TranslateLoader,
            useClass: TranslateLanguageLoader
          }
        }),
        BrowserAnimationsModule,
        FlexLayoutModule,
        LinkyModule,
    ],
    exports: [
      TranslateModule],
    declarations: [
        AppComponent,
        LoginComponent, LoginControlComponent,
        AuthCallbackComponent,
        RegisterComponent,
        ConfirmEmailComponent,
        RecoverPasswordComponent,
        ResetPasswordComponent,
        HomeComponent,
        AboutComponent,
        NotFoundComponent,
        CheckEmailComponent,
        ConfirmationDialogComponent,
    ],
    providers: [
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {float: 'always'}},
        { provide: ErrorHandler, useClass: AppErrorHandler },
        AlertService,
        UtilitiesService,
        ConfigurationService,
        RouterExtService,
        AppTitleService,
        AppTranslationService,
        NotificationService,
        NotificationEndpoint,
        AccountService,
        AccountEndpoint,
        LocalStoreManager,
        OidcHelperService,
        OrganizationEndpoint,
        OrganizationService,
        BlobStorage,
        CommentsService,
        CommentsEndpoint,
        TimeTrackingService,
        TimeTrackingEndpoint,
        TaskService,
        TaskEndpoint,
        ProjectService,
        ProjectEndpoint,
        LogService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
