import { Document } from "./document.model";
export class Task {
  id: number;
  name: string;
  details: string;
  active: boolean;
  dueDate: Date;
  timeEstimate: number;
  timeLeft: number;
  notes: string;
  approver: string;
  taskStatus: any;
  projectId: number;
  projectName?: string;
  assignedToId: string;
  assignedTo?: string;
  billable: boolean;
  deleted?: boolean;
  parentTaskId?: number;
  dependentTaskId: number;
  approvalTaskId?: number;
  helpTaskId?: number;
  organizationId?: number;
  taskType: TaskType;
  taskStatusForDisplay?:any;
  createdBy?: string;
  focused?:any;
  createdOn? : Date;
  documents?: Document[] = [];
}
export enum TaskType {
  Action = 0,
  Help = 1,
  Review = 2
}
export enum TaskStatus {
  NotReady = 1,
  Ready = 2,
  Paused = 3,
  Blocked = 4,
  InReview = 5,
  Completed = 6,
  Archived = 7
}
export enum TaskStatusForDisplay {
  'Not Ready' = 1,
  'Ready' = 2,
  'Paused' = 3,
  'Blocked' = 4,
  'In Review' = 5,
  'Completed' = 6,
  'Archived' = 7
}

export const TaskStatusEnum = [
    {id: 1, name: 'Not Ready'},
    {id: 2, name: 'Ready'},
    {id: 3, name: 'Paused'},
    {id: 4, name: 'Blocked'},
    {id: 5, name: 'In Review'},
    {id: 6, name: 'Completed'},
]

export enum TaskCreationType {
  Action,
  Help,
  Review
}
export class TaskComment {
  public id?: number;
  public createdOn?: Date;
  public createdOnLocale?:any;
  public taskId: number;
  public userId: string;
  public message: string;
  public userName?: string;
  public profilePictureUrl?: string;
  public commentType?: number;
  public filename?: string;
  public url?: string;
  public icon?: string;
}


export interface RelatedTask {
    id: any;
    taskStatus: TaskStatus;
    taskType: TaskType;
    name: string;
    dueDate: string;
    timeEstimate: number;
    timeLeft: number;
    notes: string;
    approvalTaskId: number;
    details: string;
    parentTaskId: number | null;
    dependentTaskId: number | null;
    helpTaskId: number | null;
}

export class TaskRelationShips {
    helpTask: RelatedTask;
    dependentTask: RelatedTask;
    approvalTask: RelatedTask;
}
