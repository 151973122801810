import { Comment } from './../models/comment-model';
import { Task, TaskComment } from './../models/task.model';
import { CommentsEndpoint } from './comments-endpoint.service';
import { Injectable } from '@angular/core';
import { PermissionValues } from '../models/permission.model';
import { AuthService } from './auth.service';

@Injectable()
export class CommentsService {

  constructor(
    private authService: AuthService,
    private commentsEndpoint: CommentsEndpoint
  ) { }

  updateComment(comment:TaskComment){
    return this.commentsEndpoint.updateCommentEndpoint<TaskComment>(comment);
  }

  markAsRead(id:number){
    return this.commentsEndpoint.markAsReadEndpoint(id);
  }

  getCommentsByTaskId(id:number) {
    console.log("getting comments")
    return this.commentsEndpoint.getCommentsByTaskIdEndpoint<TaskComment[]>(id);
  }

  getCommentsByProjectId(task: Task) {
    return this.commentsEndpoint.getCommentsByProjectIdEndpoint<TaskComment[]>(task.projectId, task.id);
  }

  newComment(comment: TaskComment) {
    return this.commentsEndpoint.getNewCommentEndpoint<TaskComment>(comment);
  }

  deleteComment(comment: TaskComment) {
    return this.commentsEndpoint.deleteCommentEndpoint<TaskComment>(comment.id);
  }

  userHasPermission(permissionValue: PermissionValues): boolean {
    return this.permissions.some(p => p === permissionValue);
  }

  get permissions(): PermissionValues[] {
    return this.authService.userPermissions;
  }
}
