import { QueueTask } from './../models/queue-task.model';
import { Injectable } from '@angular/core';
import { TaskEndpoint } from './task-endpoint.service';
import { RelatedTask, Task, TaskRelationShips } from '../models/task.model';
import { mergeMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PermissionValues } from '../models/permission.model';
import { AuthService } from './auth.service';

@Injectable()
export class TaskService {

  constructor(
    private authService: AuthService,
    private taskEndpoint: TaskEndpoint
  ) { }

  getTasks(page?: number, pageSize?: number) {
    return this.taskEndpoint.getTasksEndpoint<Task[]>(page, pageSize);
  }

  getAvailableDependents(projectId) {
    return this.taskEndpoint.getAvailableDependentsEndpoint<Task[]>(projectId);
  }

  getTasksByProjectId(projectId) {
    return this.taskEndpoint.getTaskByProjectIdEndpoint<Task[]>(projectId);
  }

  updateTask(task: Task) {
    return this.taskEndpoint.getUpdateTaskEndpoint<Task>(task, task.id);
  }

  createHelpTask(task: Task) {
    return this.taskEndpoint.createHelpTaskEndpoint<Task>(task);
  }

  submitTask(taskId:number) {
    return this.taskEndpoint.getSubmitTaskEndpoint<Task>(taskId);
  }

  reviseTask(taskId:number) {
    return this.taskEndpoint.getReviseTaskEndpoint<Task>(taskId);
  }

  approveTask(taskId:number) {
    return this.taskEndpoint.getApproveTaskEndpoint<Task>(taskId);
  }

  archiveTask(taskId:number) {
    return this.taskEndpoint.getArchiveTaskEndpoint<Task>(taskId);
  }

  updateTaskDocuments(task:Task){
    return this.taskEndpoint.getUpdateTaskDocumentsEndpoint<Task>(task, task.id);
  }

  newTask(task: Task) {
    return this.taskEndpoint.getNewTaskEndpoint<Task>(task, false);
  }

  importTasks(projectId: number, formData: any) {
    return this.taskEndpoint.getImportTasksEndpoint(projectId, formData);
  }

  deleteTask(taskOrTaskId: string | Task): Observable<Task> {
    if (typeof taskOrTaskId === 'string' || taskOrTaskId instanceof String) {
      return this.taskEndpoint.getDeleteTaskEndpoint<Task>(taskOrTaskId as unknown as number);
    } else {
      if (taskOrTaskId.id) {
        return this.deleteTask(taskOrTaskId.id.toString());
      } else {
        return this.taskEndpoint.getTaskByNameEndpoint<Task>(taskOrTaskId.name).pipe<Task>(
          mergeMap(task => this.deleteTask(task.id.toString())));
      }
    }
  }

  getTask(taskId: number) {
    return this.taskEndpoint.getTaskByIdEndpoint<Task>(taskId);
  }

  getQueue(page?: number, pageSize?: number) {
    return this.taskEndpoint.getQueueEndpoint<QueueTask[]>(page, pageSize);
  }

  // getTaskRelationShips(taskId:any) {
  //   return this.taskEndpoint.getTaskRelationShipsEndpoint<RelatedTask[]>(taskId);
  // }

  getTaskRelationShips(taskId:any) {
    return this.taskEndpoint.getTaskRelationShipsEndpoint<RelatedTask[]>(taskId);
  }

  userHasPermission(permissionValue: PermissionValues): boolean {
    return this.permissions.some(p => p === permissionValue);
  }

  get permissions(): PermissionValues[] {
    return this.authService.userPermissions;
  }
}
