// =============================
// Email: info@fastfoundry.com
// www.fastfoundry.com
// =============================

import { Component, NgModule } from '@angular/core';
import { APP_VERSION } from 'src/app/models/application-constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  getYear() {
    return new Date().getUTCFullYear();
  }

  getVersion() {
    return APP_VERSION;
  }
}

@NgModule({
  exports: [FooterComponent],
  declarations: [FooterComponent],
})
export class FooterModule { }
