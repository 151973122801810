import { Injectable } from '@angular/core';
import { FormGroup, ValidatorFn } from '@angular/forms';
import { isPast } from 'date-fns';
import { Document } from '../models/document.model';
import { Task, TaskStatus, TaskType } from '../models/task.model';

export interface ValidationResult {
  [key: string]: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor(
  ) { }

  trackBy(index: number, itemObject: any) {
    return itemObject.id;
  }

  formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
  }

  Form = {
    reset(formGroup: FormGroup) {
      Object.keys(formGroup.controls).forEach(
        field => {
          formGroup.get(field).setErrors(null);
        }
      );
    },

    findInvalidControls(FormGroup: FormGroup) {
      const invalid = [];
      const controls = FormGroup.controls
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
      console.log(invalid)
    },

    compareIds(id1: any, id2: any): boolean {
      const a1 = determineId(id1);
      const a2 = determineId(id2);
      return a1 === a2;
    },
  };

  Css = {
    getStatusTextColor(status: number) {
      switch (status) {
        case 1: {
          return 'text-not-ready';
        }
        case 2: {
          return 'text-ready';
        }
        case 3: {
          return 'text-paused';
        }
        case 4: {
          return 'text-blocked';
        }
        case 5: {
          return 'text-review';
        }
        case 6: {
          return 'text-completed';
        }
        //7 is archived but showing complete for now on the task list
        case 7: {
          return 'text-completed';
        }
      }
    },

    getStatus(status: number) {
      switch (status) {
        case 1: {
          return 'Not-Ready';
        }
        case 2: {
          return 'Ready';
        }
        case 3: {
          return 'Paused';
        }
        case 4: {
          return 'Blocked';
        }
        case 5: {
          return 'In-Review';
        }
        case 6: {
          return 'Completed';
        }
        //7 is archived but showing complete for now on the task list
        case 7: {
          return 'Completed';
        }
      }
    },

    getTextColor(status: number) {
      switch (status) {
        case 1: {
          return 'text-not-ready';
        }
        case 2: {
          return 'text-ready';
        }
        case 3: {
          return 'text-paused';
        }
        case 4: {
          return 'text-blocked';
        }
        case 5: {
          return 'text-review';
        }
        case 6: {
          return 'text-completed';
        }
        //7 is archived but showing complete for now on the task list
        case 7: {
          return 'text-completed';
        }
      }
    },

    getHeaderColor(tasktype: number) {
      switch (tasktype) {
        case 0: {
          return 'header-action';
        }
        case 1: {
          return 'header-help';
        }
        case 2: {
          return 'header-review';
        }
      }
    },

    showPassword(input: any): any {
      input.type = input.type === 'password' ? 'text' : 'password';
    },

    progressBarColor(task: Task) {
      if (task.timeLeft <= 0) {
        return "Blocked"
      }
      else {
        return this.getProgressBarColor(task.taskStatus);
      }
    },

    getProgressBarColor(status: number) {
      switch (status) {
        case 1: {
          return 'Not-Ready';
        }
        case 2: {
          return 'Ready';
        }
        case 3: {
          return 'Paused';
        }
        case 4: {
          return 'Blocked';
        }
        case 5: {
          return 'In-Review';
        }
        case 6: {
          return 'Completed';
        }
      }
    },

  };

  TimeUtils = {
    isPast(startTime: any) {
      const date = new Date(startTime);
      return isPast(date);
    },

    timeLeftToPercent(timeLeft: any, timeEstimated: any) {
      var timeRemaining = ((timeEstimated - timeLeft) / timeEstimated) * 100;
      return Math.round(timeRemaining);
    },

    convertDecimalToTimeStamp(decimal: number) {
      decimal = decimal * 60 * 60;
      var hours = Math.floor((decimal / (60 * 60)));
      decimal = decimal - (hours * 60 * 60);
      var minutes = Math.floor((decimal / 60));
      decimal = decimal - (minutes * 60);
      var seconds = Math.round(decimal);
      let formattedHours = hours.toString();
      let formattedMinutes = minutes.toString();
      let formattedSeconds = seconds.toString();
      if (hours < 10) {
        formattedHours = "0" + hours;
      }
      if (minutes < 10) {
        formattedMinutes = "0" + minutes;
      }
      if (seconds < 10) {
        formattedSeconds = "0" + seconds;
      }
      return ("" + formattedHours + ":" + formattedMinutes + ":" + formattedSeconds);
    },

    convertToHHMM(number) {
      var n = new Date(0, 0);
      n.setSeconds(+number * 60 * 60);
      return (n.toTimeString().slice(0, 8));
    },
  }

  FileUtils = {
     convertToBase64(url, callback) {
      var img = new Image();

      // onload fires when the image is fully loadded, and has width and height

      img.onload = function () {

        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png"),
          dataURL = dataURL.replace(/^data:image\/(png|jpg);base64,/, "");

        callback(dataURL); // the base64 string

      };

      // set attributes and src
      img.setAttribute('crossOrigin', 'anonymous'); //
      img.src = url;
    },

    //get everything after the last /
    formatFileName(fileName: string) {
      var t = fileName.substr(fileName.lastIndexOf("/") + 1);
      return t;
    },

    //get the prefix before the last /
    getPrefix(fileName: string) {
      var t = fileName.substr(fileName.lastIndexOf("/") - 1);
      var prefix = t.split('/')[0];
      return prefix;
    },

    getFileType(fileName: string) {
      return fileName.split('.').pop();
    },

    //see if the file is an image
    checkURL(url: any) {
      return (url.match(/\.(jpeg|jpg|gif|png|JPG)$/) != null);
    },

    trimFileName(doc: Document) {
      var leftRightStrings = doc.fileName.split('.');
      //file name
      var fName = leftRightStrings[0];
      //file extension
      var fExtension = leftRightStrings[1];
      var lengthFname = fName.length;
      //if file name without extension contains more than 15 characters
      if (lengthFname > 15) {
        return fName.substr(0, 10) + fName.substr(-5) + '.' + fExtension;
      }
      else {
        return doc.fileName;
      }
    },

    trimHelpFile(file: File) {
      var leftRightStrings = file.name.split('.');
      //file name
      var fName = leftRightStrings[0];
      //file extension
      var fExtension = leftRightStrings[1];
      var lengthFname = fName.length;
      //if file name without extension contains more than 15 characters
      if (lengthFname > 15) {
        return fName.substr(0, 10) + fName.substr(-5) + '.' + fExtension;
      }
      else {
        return file.name;
      }
    }
  }

  ValidationUtils = {
    dateRangeValidator(min: Date): ValidatorFn {
      return control => {
        if (!control.value) return null;

        const dateValue = new Date(control.value);

        if (min && dateValue <= min) {
          return { message: 'error message' };
        }
        null;
      }
    }
  }

  fetchTaskStatus(x: Task, revision?: boolean) {
    if (x.taskType == TaskType.Action && x.approvalTaskId > 0) {
      return TaskStatus.InReview;
    }
    if (x.taskType == TaskType.Action && x.approvalTaskId == 0) {
      return TaskStatus.Completed;
    }
    if (x.taskType == TaskType.Review && x.approvalTaskId == 0) {
      return TaskStatus.Completed;
    }
  }
}

function determineId(id: any): string {
  if (id?.constructor.name === 'array' && id.length > 0) {
    return '' + id[0];
  }
  return '' + id;
}

export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return null
}
