// =============================
// Email: info@fastfoundry.com
// www.fastfoundry.com
// =============================

import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CueMaterialModule } from '../modules/material.module';
import { PageHeaderComponent } from './page-header/page-header.component';
import { AppDialogComponent } from './app-dialog/app-dialog.component';
import { GroupByPipe } from '../pipes';
import { NgxMaskModule, IConfig } from 'ngx-mask';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
@NgModule({
  imports: [
    NgxMaskModule.forRoot(),
    FlexLayoutModule,
    FormsModule, ReactiveFormsModule,
    CueMaterialModule,
  ],
  exports: [
    FlexLayoutModule,
    FormsModule, ReactiveFormsModule,
    CueMaterialModule,
    PageHeaderComponent,
    GroupByPipe,
    AppDialogComponent,
  ],
  declarations: [
    PageHeaderComponent,
    GroupByPipe,
    AppDialogComponent,
  ]
})
export class SharedModule {

}
