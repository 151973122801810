import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';;
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from './configuration.service';

@Injectable()
export class ProjectEndpoint extends EndpointBase {
  get projectsUrl() { return this.configurations.baseUrl + '/api/projects'; }
  get projectsPublicUrl() { return this.configurations.baseUrl + '/api/public/projects'; }
  get projectByNameUrl() { return this.configurations.baseUrl + '/api/projects/name'; }
  get projectByIdUrl() { return this.configurations.baseUrl + '/api/projects/id'; }

  constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
      super(http, authService);
  }

  getProjectsEndpoint<T>(page?: number, pageSize?: number): Observable<T> {
  const endpointUrl = page && pageSize ? `${this.projectsUrl}/${page}/${pageSize}` : this.projectsUrl;

  return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
      return this.handleError(error, () => this.getProjectsEndpoint(page, pageSize));
      }));
  }

  getProjectByNameEndpoint<T>(projectName: string): Observable<T> {
      const endpointUrl = `${this.projectByNameUrl}/${projectName}`;

      return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
        catchError(error => {
          return this.handleError(error, () => this.getProjectByNameEndpoint(projectName));
        }));
    }

    getProjectByIdEndpoint<T>(projectId: number): Observable<T> {
      const endpointUrl = `${this.projectByIdUrl}/${projectId}`;

      return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
        catchError(error => {
          return this.handleError(error, () => this.getProjectByIdEndpoint(projectId));
        }));
    }

  getNewProjectEndpoint<T>(projectObject: any, isPublicRegistration?: boolean): Observable<T> {
      const endpointUrl = isPublicRegistration ? this.projectsPublicUrl : this.projectsUrl;

      return this.http.post<T>(endpointUrl, JSON.stringify(projectObject), this.requestHeaders).pipe<T>(
        catchError(error => {
          return this.handleError(error, () => this.getNewProjectEndpoint(projectObject));
        }));
    }

  getUpdateProjectEndpoint<T>(projectObject: any, projectId: number): Observable<T> {
    const endpointUrl = this.projectsUrl + '/' + projectId;

    return this.http.put<T>(endpointUrl, JSON.stringify(projectObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateProjectEndpoint(projectObject, projectId));
      }));
  }

  getDeleteProjectEndpoint<T>(projectId: number): Observable<T> {
    const endpointUrl = `${this.projectsUrl}/${projectId}`;

    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteProjectEndpoint(projectId));
      }));
  }
}
