import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';;
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from './configuration.service';
import { TimeDuration, TimeTrackingEntry } from '../models/timetracking.model';

@Injectable()
export class TimeTrackingEndpoint extends EndpointBase {
  get timeTrackingUrl() { return this.configurations.baseUrl + '/api/timetracking'; }
  get lastTimeEntryStateUrl() { return this.configurations.baseUrl + '/api/timetracking/getlasttimeentrystate/id'; }
  get recordTimeEntryUrl() { return this.configurations.baseUrl + '/api/timetracking/recordtimeentry'; }
  get recordStopTimeEntryUrl() { return this.configurations.baseUrl + '/api/timetracking/recordstoptimeentry'; }
  get updateTimeEntryUrl() { return this.configurations.baseUrl + '/api/timetracking/updatetimeentry'; }
  get timeTrackingByIdUrl() { return this.configurations.baseUrl + '/api/timetracking/id'; }
  get checkTimeSinceStartedUrl() { return this.configurations.baseUrl + '/api/timetracking/checkTimeSinceStarted'; }

  constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
      super(http, authService);
  }


  checkTimeSinceStarted<T>(taskId: number): Observable<T> {
    const endpointUrl = `${this.checkTimeSinceStartedUrl}/${taskId}`
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
        catchError(error => {
        return this.handleError(error, () => this.checkTimeSinceStarted(taskId));
        }));
    }

  getLastTimeEntryState<T>(id: number): Observable<T> {
    const endpointUrl = `${this.timeTrackingUrl}/${id}`

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
        catchError(error => {
        return this.handleError(error, () => this.getTimeTrackingEndpoint(id));
        }));
    }

  getTimeTrackingEndpoint<T>(page?: number, pageSize?: number): Observable<T> {
  const endpointUrl = page && pageSize ? `${this.timeTrackingUrl}/${page}/${pageSize}` : this.timeTrackingUrl;

  return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
      return this.handleError(error, () => this.getTimeTrackingEndpoint(page, pageSize));
      }));
  }

  getRecordTimeEntryEndpoint<T>(id: number): Observable<T> {
    //const endpointUrl = `${this.recordTimeEntryUrl}/${id}`
    const endpointUrl = this.recordTimeEntryUrl;
    return this.http.post<T>(endpointUrl, JSON.stringify(id), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getRecordTimeEntryEndpoint(id));
      }));
  }

  getUpdateTimeEndpoint<T>(timeObject: TimeTrackingEntry): Observable<T> {
    return this.http.put<T>(this.updateTimeEntryUrl, JSON.stringify(timeObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateTimeEndpoint(timeObject));
      }));
  }

  getStartTimeEndpoint<T>(id: number): Observable<T> {
    return this.http.post<T>(this.timeTrackingUrl + '/StartTimeEntry/', JSON.stringify(id), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getStartTimeEndpoint(id));
      }));
  }

  // getStopTimeEndpoint<T>(id: number): Observable<T> {
  //   return this.http.post<T>(this.timeTrackingUrl + '/StopTimeEntry/', JSON.stringify(id), this.requestHeaders).pipe<T>(
  //     catchError(error => {
  //       return this.handleError(error, () => this.getStartTimeEndpoint(id));
  //     }));
  // }

  getStopTimeEndpoint<T>(data:TimeDuration): Observable<T> {
    return this.http.post<T>(this.timeTrackingUrl + '/StopTimeEntry/', JSON.stringify(data), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getStopTimeEndpoint(data));
      }));
  }


}
