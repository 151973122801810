export class Organization {

  public id: number;
  public name: string;
  public phone: string;
  public cellPhone: string;
  public fax: string;
  public address: string;
  public address2: string;
  public city: string;
  public state: string;
  public postalCode: string;
  public billToName: string;
  public billToName2: string;
  public billToAddress: string;
  public billToAddress2: string;
  public billToCity: string;
  public billToState: string;
  public billToPostalCode: string;
  public billToPhone: string;
  public notes: string;
  public ownerId: string;
  public logoUrl?:string;
  public ownerName?: string;
  public adminUserName?:any;
}
