import { User } from 'src/app/models/user.model';
import { Task } from 'src/app/models/task.model';
import { EditTaskDialogComponent } from './components/tasks/edit-task-dialog/edit-task-dialog.component';
// =============================
// Email: info@fastfoundry.com
// www.fastfoundry.com
// =============================

import { Component, ChangeDetectorRef, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastaService, ToastaConfig, ToastOptions, ToastData } from 'ngx-toasta';

import { AlertService, AlertCommand, MessageSeverity } from './services/alert.service';
import { NotificationService } from './services/notification.service';
import { AppTranslationService } from './services/app-translation.service';
import { AccountService } from './services/account.service';
import { LocalStoreManager } from './services/local-store-manager.service';
import { AppTitleService } from './services/app-title.service';
import { AuthService } from './services/auth.service';
import { ConfigurationService } from './services/configuration.service';
import { Permission } from './models/permission.model';
import { getLogoUrl } from './utilities';
import { fadeInOut } from './services/animations';
import { RouterExtService } from './services/router-extension.service';
import { QuickTaskDialogComponent } from './components/tasks/quick-task-dialog/quick-task-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeInOut],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('admin') adminExpander: MatExpansionPanel;

  private _mobileQueryListener: () => void;
  isAppLoaded: boolean;
  isUserLoggedIn: boolean;
  isAdminExpanded = false;
  removePrebootScreen: boolean;
  newNotificationCount = 0;
  appTitle = 'Cards';
  imagePath: any;
  mobileQuery: MediaQueryList;
  stickyToasties: number[] = [];
  dataLoadingConsecutiveFailures = 0;
  notificationsLoadingSubscription: any;
  areaName:any;
  newArea:any;
  profileNavVisible = false;
  mainNavVisible = false;
  user:User;

  gT = (key: string | Array<string>, interpolateParams?: object) => this.translationService.getTranslation(key, interpolateParams);

  get notificationsTitle() {
    if (this.newNotificationCount) {
      return `${this.gT('app.Notifications')} (${this.newNotificationCount} ${this.gT('app.New')})`;
    } else {
      return this.gT('app.Notifications');
    }
  }

  constructor(
    storageManager: LocalStoreManager,
    private toastaService: ToastaService,
    private toastaConfig: ToastaConfig,
    private accountService: AccountService,
    private alertService: AlertService,
    private notificationService: NotificationService,
    private appTitleService: AppTitleService,
    private authService: AuthService,
    private translationService: AppTranslationService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public configurations: ConfigurationService,
    public router: Router,
    public dialog: MatDialog,
    changeDetectorRef: ChangeDetectorRef,
    private routerExtensionService: RouterExtService,
    media: MediaMatcher) {

    this.matIconRegistry.addSvgIconSet(this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/custom-icons.svg'));
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    storageManager.initialiseStorageSyncListener();

    this.toastaConfig.theme = 'bootstrap';
    this.toastaConfig.position = 'top-right';
    this.toastaConfig.limit = 100;
    this.toastaConfig.showClose = true;
    this.toastaConfig.showDuration = false;

    this.appTitleService.appName = this.appTitle;
    this.imagePath = getLogoUrl();
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
       this.setAreaName(e.url);
      }
    });
  }

  ngOnInit() {
    this.isUserLoggedIn = this.authService.isLoggedIn;
    // 0.5 extra sec to display preboot/loader information. Preboot screen is removed 0.5 sec later
    setTimeout(() => this.isAppLoaded = true, 500);
    setTimeout(() => this.removePrebootScreen = true, 1000);
    this.alertService.getMessageEvent().subscribe(message => this.showToast(message));
    this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
      this.isUserLoggedIn = isLoggedIn;
    });

    if(!this.mobileQuery.matches){
      this.mainNavVisible = true;
    }
    // this.mdbSpinningPreloader.stop();
  }

  setAreaName(route:any){
    console.log(route)
    this.newArea = true;
    setTimeout(() => this.newArea = false, 900);
    if(route.toLowerCase().includes('settings/organization-settings')){
      this.areaName = 'Organization Settings'
    }
    else if(route.toLowerCase().includes('settings')){
      this.areaName = 'Profile'
    }
    else if(route.toLowerCase().includes('stack/card')){
      this.areaName = 'Card'
    }

   else{
    switch(route){
      case '/login':
        this.areaName = 'Cards'
        break;
      case '/stack':
        this.areaName = 'Stack'
        break;
      case '/projects':
        this.areaName = 'Projects'
        break;
      case '/tasks':
        this.areaName = 'Cards'
        break;
      case '/customers':
        this.areaName = 'Customers'
        break;
      case '/admin/organizations':
        this.areaName = 'Organizations'
        break;
      case '/admin/roles':
        this.areaName = 'Roles'
        break;
      case '/admin/users':
        this.areaName = 'Users'
        break;
      case '/recoverpassword':
        this.areaName = ''
        break;
      case '/register':
        this.areaName = 'Registration'
        break;
      case '/settings/organization-settings':
        this.areaName = 'Organization Settings'
        break;
      default:
          this.areaName = 'Cards'
    }
   }
  }

  ngOnDestroy() {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.unsubscribeNotifications();
  }

  private unsubscribeNotifications() {
    if (this.notificationsLoadingSubscription) {
      this.notificationsLoadingSubscription.unsubscribe();
    }
  }

  initNotificationsLoading() {
    this.notificationsLoadingSubscription = this.notificationService.getNewNotificationsPeriodically()
      .subscribe(notifications => {
        this.dataLoadingConsecutiveFailures = 0;
        this.newNotificationCount = notifications.filter(n => !n.isRead).length;
      },
        error => {
          this.alertService.logError(error);

          if (this.dataLoadingConsecutiveFailures++ < 20) {
            setTimeout(() => this.initNotificationsLoading(), 5000);
          } else {
            this.alertService.showStickyMessage('Load Error', 'Loading new notifications from the server failed!', MessageSeverity.error);
          }
        });
  }

  markNotificationsAsRead() {
    const recentNotifications = this.notificationService.recentNotifications;

    if (recentNotifications.length) {
      this.notificationService.readUnreadNotification(recentNotifications.map(n => n.id), true)
        .subscribe(response => {
          for (const n of recentNotifications) {
            n.isRead = true;
          }

          this.newNotificationCount = recentNotifications.filter(n => !n.isRead).length;
        },
          error => {
            this.alertService.logError(error);
            this.alertService.showMessage('Notification Error', 'Marking read notifications failed', MessageSeverity.error);

          });
    }
  }

  showToast(alert: AlertCommand) {

    if (alert.operation === 'clear') {
      for (const id of this.stickyToasties.slice(0)) {
        this.toastaService.clear(id);
      }

      return;
    }

    const toastOptions: ToastOptions = {
      title: alert.message.summary,
      msg: alert.message.detail,
    };


    if (alert.operation === 'add_sticky') {
      toastOptions.timeout = 2000;

      toastOptions.onAdd = (toast: ToastData) => {
        this.stickyToasties.push(toast.id);
      };

      toastOptions.onRemove = (toast: ToastData) => {
        const index = this.stickyToasties.indexOf(toast.id, 0);

        if (index > -1) {
          this.stickyToasties.splice(index, 1);
        }

        if (alert.onRemove) {
          alert.onRemove();
        }

        toast.onAdd = null;
        toast.onRemove = null;
      };
    } else {
      toastOptions.timeout = 2000;
    }


    switch (alert.message.severity) {
      case MessageSeverity.default: this.toastaService.default(toastOptions); break;
      case MessageSeverity.info: this.toastaService.info(toastOptions); break;
      case MessageSeverity.success: this.toastaService.success(toastOptions); break;
      case MessageSeverity.error: this.toastaService.error(toastOptions); break;
      case MessageSeverity.warn: this.toastaService.warning(toastOptions); break;
      case MessageSeverity.wait: this.toastaService.wait(toastOptions); break;
    }
  }

  addCard(task?: Task){
    const dialogRef = this.dialog.open(EditTaskDialogComponent,
      {
        disableClose: true,
        panelClass: this.mobileQuery.matches? 'fullscreen-dialog' : '',
        width: this.mobileQuery.matches? '100vw' : '600px',
        autoFocus: false,
        data: { task, fromNav:true },
      });
    dialogRef.afterClosed().subscribe(c => {
      if (c) {
        if(this.areaName == 'Stack'){
          window.location.reload();
        }
      }
    });
  }

  quickCard(task?: Task){
    const dialogRef = this.dialog.open(QuickTaskDialogComponent,
      {
        disableClose: true,
        panelClass: this.mobileQuery.matches? 'fullscreen-dialog' : '',
        width: this.mobileQuery.matches? '100vw' : '600px',
        autoFocus: false,
        data: { task, fromNav:true },
      });
    dialogRef.afterClosed().subscribe(c => {
      if (c) {
        if(this.areaName == 'Stack'){
          window.location.reload();
        }
      }
    });
  }


  logout() {
    this.authService.logout();
    this.authService.redirectLogoutUser();
  }

  get userName(): string {
    return this.authService.currentUser ? this.authService.currentUser.userName : '';
  }

  get fullName(): string {
    return this.authService.currentUser ? this.authService.currentUser.fullName : '';
  }

  get canViewOrganizations() {
    return this.accountService.userHasPermission(Permission.viewOrganizationsPermission);
  }

  get canViewProjects() {
    return this.accountService.userHasPermission(Permission.viewProjectsPermission);
  }

  get canManageProjects() {
    return this.accountService.userHasPermission(Permission.manageProjectsPermission);
  }

  get canViewTasks() {
    return this.accountService.userHasPermission(Permission.viewTasksPermission);
  }

  get canViewCustomers() {
    return this.accountService.userHasPermission(Permission.viewCustomersPermission);
  }

  get canViewQueue() {
    return this.accountService.userHasPermission(Permission.viewQueuePermission);
  }

  get canViewUsers() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission);
  }

  get canViewRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }

  get canManageRoles() {
    return this.accountService.userHasPermission(Permission.manageRolesPermission);
  }
}
