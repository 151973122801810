import { Injectable } from '@angular/core';
import { ProjectEndpoint } from './project-endpoint.service';
import { Project } from '../models/project.model';
import { mergeMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PermissionValues } from '../models/permission.model';
import { AuthService } from './auth.service';

@Injectable()
export class ProjectService {

  constructor(
    private authService: AuthService,
    private projectEndpoint: ProjectEndpoint
  ) { }

  getProjects(page?: number, pageSize?: number) {
    return this.projectEndpoint.getProjectsEndpoint<Project[]>(page, pageSize);
  }

  getProject(projectId: number) {
    return this.projectEndpoint.getProjectByIdEndpoint<Project>(projectId);
  }

  updateProject(project: Project) {
    if (project.id) {
      return this.projectEndpoint.getUpdateProjectEndpoint(project, project.id);
    } else {
      return this.projectEndpoint.getProjectByNameEndpoint<Project>(project.name).pipe(
        mergeMap(foundProject => {
          project.id = foundProject.id;
          return this.projectEndpoint.getUpdateProjectEndpoint(project, project.id);
        }));
    }
  }

  newProject(project: Project) {
    return this.projectEndpoint.getNewProjectEndpoint<Project>(project, false);
  }

  deleteProject(projectOrProjectId: string | Project): Observable<Project> {
    if (typeof projectOrProjectId === 'string' || projectOrProjectId instanceof String) {
      return this.projectEndpoint.getDeleteProjectEndpoint<Project>(projectOrProjectId as unknown as number);
    } else {
      if (projectOrProjectId.id) {
        return this.deleteProject(projectOrProjectId.id.toString());
      } else {
        return this.projectEndpoint.getProjectByNameEndpoint<Project>(projectOrProjectId.name).pipe<Project>(
          mergeMap(project => this.deleteProject(project.id.toString())));
      }
    }
  }

  userHasPermission(permissionValue: PermissionValues): boolean {
    return this.permissions.some(p => p === permissionValue);
  }

  get permissions(): PermissionValues[] {
    return this.authService.userPermissions;
  }
}
