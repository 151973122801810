import { Task } from 'src/app/models/task.model';
import { Injectable } from '@angular/core';
import { PermissionValues } from '../models/permission.model';
import { AuthService } from './auth.service';
import { TimeTrackingEndpoint } from './timetracking-endpoint.service';
import { TimeDuration, TimeTracking, TimeTrackingEntry } from '../models/timetracking.model';

@Injectable()
export class TimeTrackingService {

  constructor(
    private authService: AuthService,
    private timeTrackingEndpoint: TimeTrackingEndpoint
  ) { }

  getTimes(page?: number, pageSize?: number) {
    return this.timeTrackingEndpoint.getTimeTrackingEndpoint<TimeTracking[]>(page, pageSize);
  }

  getCheckTimeSinceStarted(id: number) {
    return this.timeTrackingEndpoint.checkTimeSinceStarted<any>(id);
  }

  getLastTimeEntryState(id: number) {
    return this.timeTrackingEndpoint.getLastTimeEntryState<boolean>(id);
  }

  recordTimeEntry(id: number) {
    return this.timeTrackingEndpoint.getRecordTimeEntryEndpoint<Task>(id);
  }

  updateTimeEntry(timetrackingentry: TimeTrackingEntry) {
    return this.timeTrackingEndpoint.getUpdateTimeEndpoint<TimeTrackingEntry>(timetrackingentry);
  }

  //TODO redo the the time entry methods since we are having some weird issues with them
  startTimeEntry(id: number) {
    return this.timeTrackingEndpoint.getStartTimeEndpoint<number>(id);
  }

  stopTimeEntry(data:TimeDuration) {
    return this.timeTrackingEndpoint.getStopTimeEndpoint<Task>(data);
    // return this.timeTrackingEndpoint.getRecordStopTimeEntryEndpoint<number>(id);
  }

  userHasPermission(permissionValue: PermissionValues): boolean {
    return this.permissions.some(p => p === permissionValue);
  }

  get permissions(): PermissionValues[] {
    return this.authService.userPermissions;
  }
}
