import { Injectable } from '@angular/core';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from './configuration.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class OrganizationEndpoint extends EndpointBase {
    get orgsUrl() { return this.configurations.baseUrl + '/api/organizations'; }
    get orgByNameUrl() { return this.configurations.baseUrl + '/api/organizations/name'; }

    constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
        super(http, authService);
    }

    getOrgsEndpoint<T>(page?: number, pageSize?: number): Observable<T> {
        const endpointUrl = page && pageSize ? `${this.orgsUrl}/${page}/${pageSize}` : this.orgsUrl;
    
        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError(error => {
            return this.handleError(error, () => this.getOrgsEndpoint(page, pageSize));
            }));
        }
    
    getOrgByNameEndpoint<T>(orgName: string): Observable<T> {
        const endpointUrl = `${this.orgByNameUrl}/${orgName}`;
    
        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError(error => {
            return this.handleError(error, () => this.getOrgByNameEndpoint(orgName));
            }));
        }

    getOrgByIdEndpoint<T>(id:number): Observable<T> {
        const endpointUrl = `${this.orgsUrl}/${id}`;    
        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError(error => {
            return this.handleError(error, () => this.getOrgByIdEndpoint(id));
            }));
        }

    getNewOrgEndpoint<T>(orgObject: any): Observable<T> {
        const endpointUrl = this.orgsUrl;
    
        return this.http.post<T>(endpointUrl, JSON.stringify(orgObject), this.requestHeaders).pipe<T>(
            catchError(error => {
            return this.handleError(error, () => this.getNewOrgEndpoint(orgObject));
            }));
        }
    
    getUpdateOrgEndpoint<T>(orgObject: any, orgId: number): Observable<T> {
        const endpointUrl = this.orgsUrl + "/" + orgId;
    
        return this.http.put<T>(endpointUrl, JSON.stringify(orgObject), this.requestHeaders).pipe<T>(
        catchError(error => {
            return this.handleError(error, () => this.getUpdateOrgEndpoint(orgObject, orgId));
        }));
    }

    getDeleteOrgEndpoint<T>(orgId: number): Observable<T> {
        const endpointUrl = `${this.orgsUrl}/${orgId}`;
    
        return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
        catchError(error => {
            return this.handleError(error, () => this.getDeleteOrgEndpoint(orgId));
        }));
    }

}