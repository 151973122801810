export type PermissionNames =
    'View Users' | 'Manage Users' |
    'View Roles' | 'Manage Roles' | 'Assign Roles' |
    'View Organizations' | 'Manage Organizations' |
    'View Projects' | 'Manage Projects' |
    'View Tasks' | 'Manage Tasks' |
    'View Customers' | 'Manage Customers' |
    'View Queue' | 'Manage Queue';


export type PermissionValues =
    'users.view' | 'users.manage' |
    'roles.view' | 'roles.manage' | 'roles.assign' |
    'organizations.view' | 'organizations.manage' |
    'projects.view' | 'projects.manage' |
    'tasks.view' | 'tasks.manage' |
    'customers.view' | 'customers.manage' |
    'queue.view' | 'queue.manage';


export class Permission {

    public static readonly viewUsersPermission: PermissionValues = 'users.view';
    public static readonly manageUsersPermission: PermissionValues = 'users.manage';

    public static readonly viewRolesPermission: PermissionValues = 'roles.view';
    public static readonly manageRolesPermission: PermissionValues = 'roles.manage';
    public static readonly assignRolesPermission: PermissionValues = 'roles.assign';

    public static readonly viewOrganizationsPermission: PermissionValues = 'organizations.view';
    public static readonly manageOrganizationsPermission: PermissionValues = 'organizations.manage';

    public static readonly viewProjectsPermission: PermissionValues = 'projects.view';
    public static readonly manageProjectsPermission: PermissionValues = 'projects.manage';

    public static readonly viewTasksPermission: PermissionValues = 'tasks.view';
    public static readonly manageTasksPermission: PermissionValues = 'tasks.manage';

    public static readonly viewCustomersPermission: PermissionValues = 'customers.view';
    public static readonly manageCustomersPermission: PermissionValues = 'customers.manage';

    public static readonly viewQueuePermission: PermissionValues = 'queue.view';
    public static readonly manageQueuePermission: PermissionValues = 'queue.manage';

    constructor(name?: PermissionNames, value?: PermissionValues, groupName?: string, description?: string) {
        this.name = name;
        this.value = value;
        this.groupName = groupName;
        this.description = description;
    }

    public name: PermissionNames;
    public value: PermissionValues;
    public groupName: string;
    public description: string;
}
