import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AccountService } from '../../services/account.service';
import { AuthService } from '../../services/auth.service';
import { Utilities } from '../../services/utilities';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { UpdateAndConfirm } from './confirm-email.model';
import { UserLogin } from 'src/app/models/user.model';
import { EqualValidator } from 'src/app/shared/validators/equal.validator';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss']
})


export class ConfirmEmailComponent implements OnInit {
  isLoading = false;
  isSuccess: boolean;
  message: string;
  private _mobileQueryListener: () => void;
  mobileQuery: MediaQueryList;
  updatePasswordForm: FormGroup;
  userId: string;
  emailConfirmationCode: string;
  newPasswordVisible = false;
  confirmPasswordVisible = false;
  currentPasswordVisible = false;
  get newPassword() { return this.updatePasswordForm.get('password').get('newPassword'); }
  get confirmPassword() { return this.updatePasswordForm.get('password').get('confirmPassword'); }
  get currentPassword() { return this.updatePasswordForm.get('currentPassword')}

  constructor(
    private route: ActivatedRoute,
    private alertService: AlertService,
    private accountService: AccountService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private formBuilder: FormBuilder,
    private media: MediaMatcher,
    private authService: AuthService) {
      this.mobileQuery = media.matchMedia('(max-width: 600px)');
      this._mobileQueryListener = () => changeDetectorRef.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.buildForm();

    this.route.queryParams.subscribe(params => {
      const loweredParams: any = Utilities.GetObjectWithLoweredPropertyNames(params);
      this.userId = loweredParams.userid;
    });

    //hide the information in the url
    history.replaceState({}, '', location.pathname);

  }

  buildForm() {
    this.updatePasswordForm = this.formBuilder.group({
      currentPassword: ['', Validators.required],
      password: this.formBuilder.group({
        newPassword: ['', [Validators.required, Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,}/)]],
        confirmPassword: ['', [Validators.required, EqualValidator('newPassword')]]
      })
    });
  }

  updatePassword() {

    if (!this.updatePasswordForm.valid) {
      this.alertService.showStickyMessage('Invalid Form', 'Please make sure the form is valid', MessageSeverity.error);
      return;
    }

    if (this.newPassword.value !== this.confirmPassword.value) {
      this.alertService.showStickyMessage('Passwords do not match', 'Please make sure the passwords match', MessageSeverity.error);
      return;
    }

    this.isLoading = true;
    const updatePassword = this.getPasswordUpdateModel();
    console.log(updatePassword)
    this.accountService.updatePassAndConfirmEmail(updatePassword)
      .subscribe(
        (x) => {
          this.alertService.showMessage('Password Updated', 'Your password has been updated successfully and your email confirmed, logging you in...', MessageSeverity.success);
          var newUserLogin = new UserLogin();
          newUserLogin.userName = x.username;
          newUserLogin.password = updatePassword.newPassword;
          setTimeout(() => {
            this.autoLogin(newUserLogin);
          }, 2000);
        },
        error => {
          this.alertService.showStickyMessage('Password Update Error', 'The below errors occured whilst updating your password:' + error, MessageSeverity.error, error);
        });
    }

  private getPasswordUpdateModel(): UpdateAndConfirm {
    const formModel = this.updatePasswordForm.value;
    return {
      currentPassword: formModel.currentPassword,
      newPassword: formModel.password.newPassword,
      userId: this.userId
    };
  }

  autoLogin(newUserLogin: UserLogin) {
    this.authService.loginWithPassword(newUserLogin).subscribe(
      user => {
        setTimeout(() => {
          this.alertService.stopLoadingMessage();
          this.isLoading = false;
          this.router.navigate(['/home']);
        }, 500);
      },
      error => {
        alert(error)
        setTimeout(() => {
          this.alertService.stopLoadingMessage();
          this.isLoading = false;
          this.router.navigate(['/login']);
        }, 500);
      });
  }


  private saveSuccessHelper() {
    this.alertService.stopLoadingMessage();
    this.isLoading = false;
    this.isSuccess = true;
  }

  private saveFailedHelper(error: any, userId: string, code: string) {
    this.alertService.stopLoadingMessage();
    this.isLoading = false;
    this.isSuccess = false;
    setTimeout(() => {
      const errorData = Utilities.getResponseBody(error);

      if (Utilities.checkNotFound(error) && errorData === userId) {
        this.alertService.showStickyMessage('Email Not Confirmed', `No user with that id was found`, MessageSeverity.error, error);
      } else {
        const errorMessage = Utilities.getHttpResponseMessage(error);

        if (errorMessage) {
          this.alertService.showStickyMessage('Email Not Confirmed', errorMessage, MessageSeverity.error, error);
        } else {
          this.alertService.showStickyMessage('Email Not Confirmed', `An error occured while confirming your email.\nError: ${errorData}`, MessageSeverity.error, error);
        }
      }
    }, 2000);
  }
}
