//import { Observable } from 'rxjs';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { OrganizationService } from 'src/app/services/organization.service';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { Utilities } from 'src/app/services/utilities';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Organization } from 'src/app/models/organization.model';
import { Permission } from 'src/app/models/permission.model';
import { fadeInOut } from 'src/app/services/animations';
import { EditOrganizationDialogComponent } from '../edit-organization-dialog/edit-organization-dialog.component';

@Component({
  selector: 'app-organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.scss'],
  animations: [fadeInOut]
})

export class OrganizationListComponent implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    displayedColumns = ['name', 'city', 'state', 'address', 'zip'];
    dataSource: MatTableDataSource<Organization>;
    sourceOrganization: Organization;
    loadingIndicator: boolean;

  constructor(
    private alertService: AlertService,
    private organizationService: OrganizationService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {
    if (true) { //this.canManageOrganizations
      this.displayedColumns.push('actions');
    }

    this.dataSource = new MatTableDataSource();
   }

  ngOnInit(): void {
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  private loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.organizationService.getOrgs().subscribe(
      organizations => this.onDataLoadSuccessful(organizations),
      error => this.onDataLoadFailed(error)
    );

  }

  private onDataLoadSuccessful(organizations: Organization[]) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.dataSource.data = organizations;
  }

  private onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve organizations from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  private updateOrganizations(organization: Organization) {
    if (this.sourceOrganization) {
      Object.assign(this.sourceOrganization, organization);
      this.alertService.showMessage('Success', `Changes to Organization \"${organization.name}\" was saved successfully`, MessageSeverity.success);
      this.sourceOrganization = null;
    } else {
      this.dataSource.data.push(organization);
      this.refresh();
      this.alertService.showMessage('Success', `Organization \"${organization.name}\" was created successfully`, MessageSeverity.success);
    }
  }

  public editOrganization(organization?: Organization) {
    this.sourceOrganization = organization;

    const dialogRef = this.dialog.open(EditOrganizationDialogComponent,
      {
        disableClose: true,
        panelClass: 'fullscreen-dialog',
        data: { organization }
      });
    dialogRef.afterClosed().subscribe(o => {
      if (o) {
        this.updateOrganizations(o);
      }
    });
  }

  public confirmDelete(organization: Organization) {
    this.snackBar.open(`Delete ${organization.name}?`, 'DELETE', { duration: 5000})
      .onAction().subscribe(() => {
        this.alertService.startLoadingMessage('Deleting...');
        this.loadingIndicator = true;

        this.organizationService.deleteOrg(organization.id.toString())
          .subscribe(results => {
            this.alertService.stopLoadingMessage();
            this.loadingIndicator = false;
            this.dataSource.data = this.dataSource.data.filter(item => item !== organization);
          },
            error => {
              this.alertService.stopLoadingMessage();
              this.loadingIndicator = false;

              this.alertService.showStickyMessage('Delete Error', `An error occured while deleting the user.\r\nError: "${Utilities.getHttpResponseMessages(error)}"`,
                MessageSeverity.error, error);
            });
      });
  }

  get canManageOrganizations() {
    return this.organizationService.userHasPermission(Permission.manageOrganizationsPermission);
  }

}



