export class User {
  // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
  constructor(id?: string, userName?: string, fullName?: string, email?: string, jobTitle?: string, phoneNumber?: string, roles?: string[], organizationId?: number, profilePictureUrl?: any, defaultProjectId?:any, defaultTimeEstimate?:any) {

    this.id = id;
    this.userName = userName;
    this.fullName = fullName;
    this.email = email;
    this.jobTitle = jobTitle;
    this.phoneNumber = phoneNumber;
    this.roles = roles;
    this.organizationId = organizationId;
    this.profilePictureUrl = profilePictureUrl;
    this.defaultProjectId = defaultProjectId;
    this.defaultTimeEstimate = defaultTimeEstimate;
  }

  get friendlyName(): string {
    let name = this.fullName || this.userName;

    if (this.jobTitle) {
      name = this.jobTitle + ' ' + name;
    }

    return name;
  }


  public id: string;
  public userName: string;
  public fullName: string;
  public email: string;
  public emailConfirmed: boolean;
  public jobTitle: string;
  public phoneNumber: string;
  public isEnabled: boolean;
  public isLockedOut: boolean;
  public roles: string[];
  public organizationId: number;
  public profilePictureUrl?: any;
  public defaultProjectId?:any;
  public defaultTimeEstimate?:number;
}

export class ProjectUser {
  public userId: string;
  public projectId: number;
  public fullName: string;
}

export class UserEdit extends User {
  constructor(currentPassword?: string, newPassword?: string, confirmPassword?: string) {
    super();

    this.currentPassword = currentPassword;
    this.newPassword = newPassword;
    this.confirmPassword = confirmPassword;
  }

  public currentPassword: string;
  public newPassword: string;
  public confirmPassword: string;
}

export class UserLogin {
  constructor(userName?: string, password?: string, rememberMe?: boolean) {
    this.userName = userName;
    this.password = password;
    this.rememberMe = rememberMe;
  }

  userName: string;
  password: string;
  rememberMe: boolean;
}
