// =============================
// Email: info@fastfoundry.com
// www.fastfoundry.com
// =============================

import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { AlertService, MessageSeverity } from './services/alert.service';

@Injectable()
export class AppErrorHandler extends ErrorHandler {

    private alertService: AlertService;

    constructor(
      private injector: Injector
    ) {
        super();
    }


    handleError(error: any) {
        //if (this.alertService == null) {
            this.alertService = this.injector.get(AlertService);
        //}

        //this.alertService.showStickyMessage("Oops!", "Something went wrong. Please reload the page to correct this error", MessageSeverity.warn);
        //this.alertService.showStickyMessage("Unhandled Error", error.message || error, MessageSeverity.error, error);

        // if (confirm('Fatal Error!\nAn unresolved error has occured. Do you want to reload the page to correct this?\n\nError: ' + error.message)) {
        //     window.location.reload();
        // }

        super.handleError(error);
    }
}
