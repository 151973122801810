// =============================
// Email: info@fastfoundry.com
// www.fastfoundry.com
// =============================

import { Pipe, PipeTransform } from '@angular/core';
import { QueueTask } from './models/queue-task.model';

@Pipe({ name: 'groupBy' })
export class GroupByPipe implements PipeTransform {

  transform(value: Array<any>, field: string): Array<any> {

    if (!value) {
      return value;
    }

    const groupedObj = value.reduce((prev, cur) => {
      if (!prev[cur[field]]) {
        prev[cur[field]] = [cur];
      } else {
        prev[cur[field]].push(cur);
      }

      return prev;
    }, {});

    return Object.keys(groupedObj).map(key => ({ key, value: groupedObj[key] }));
  }
}

@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {
  transform(items: QueueTask[], term): any {
    return term
      ? items.filter(item => item.taskStatus == term)
      : items;
  }
}

@Pipe({
  name: 'truncatetext'
})
export class TruncateTextPipe implements PipeTransform {

  transform(value: string, length: number): string {
    const biggestWord = 50;
    const elipses = "...";

    if (typeof value === "undefined") return value;
    if (value.length <= length) return value;

    //.. truncate to about correct lenght
    let truncatedText = value.slice(0, length + biggestWord);

    //.. now nibble ends till correct length
    while (truncatedText.length > length - elipses.length) {

      let lastSpace = truncatedText.lastIndexOf(" ");
      if (lastSpace === -1) break;
      truncatedText = truncatedText.slice(0, lastSpace).replace(/[!,.?;:]$/, '');

    };

    return truncatedText + elipses;

  }
}
