// =============================
// Email: info@fastfoundry.com
// www.fastfoundry.com
// =============================

import { Component } from '@angular/core';
import { fadeInOut } from '../../services/animations';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  animations: [fadeInOut]
})
export class NotFoundComponent {
}
