
export class Registration {
    public companyName: string;
    public userName: string;
    // public password: string;
    // public confirmPassword: string;
    public email: string;
    public name: string;
    public city: string;
    public state: string;
    public phone: string;
    public cellPhone: string;
    public fax: string;
    public address: string;
    public address2: string;
    public postalCode: string;
    public billToName: string;
    public billToName2: string;
    public billToAddress: string;
    public billToAddress2: string;
    public billToCity: string;
    public billToState: string;
    public billToPostalCode: string;
    public billToPhone: string;
}
