import { Subject } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  @ViewChild ('frame') public formModal: any;
  private onConfirm = new Subject();

  constructor() { }
  // constructor( modalRef: MDBModalRef) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.formModal.show();
  }

  confirm(){
    this.onConfirm.next("yes")
  }

  close(): void {
    this.onConfirm.next("no");
  }

}
